import { SERVER_METHODS, requestServer } from '../utils/request';
import ApplicationError from '../utils/applicationError';

export const fetchQuestions = async (assignmentid) => {
  const path = `/assessment/${assignmentid}`;
  try {
    const res = await requestServer(SERVER_METHODS.GET, path);
    if (res.status === 200) {
      return await res.json();
    }
    throw new ApplicationError(res.status, await res.json());
  } catch (error) {
    if (error instanceof ApplicationError) throw error;
    else
      throw JSON.stringify({
        code: 'INT',
        info: {
          msg: 'UNKNOWN ERROR'
        }
      });
  }
};

export const submitAnswers = async (answer) => {
  const path = '/feedback';
  try {
    const res = await requestServer(SERVER_METHODS.POST, path, answer);
    if (res.status === 200) {
      return await res.json();
    }
    throw new ApplicationError(res.status, await res.json());
  } catch (error) {
    if (error instanceof ApplicationError) throw error;
    else
      throw JSON.stringify({
        code: 'INT',
        info: {
          msg: 'UNKNOWN ERROR'
        }
      });
  }
};
