import { get } from 'lodash';
import * as ClientServices from 'src/services/ClientServices';
import { receiveErrors, clearErrors } from '../error/error.actions';
import { setLoading } from '../user/user.actions';
import ClientActionTypes from './client.types';

const setClients = (clients) => ({
  type: ClientActionTypes.SET_CLIENTS,
  payload: clients
});

export const getClients = (userInfo) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(clearErrors());
    const clientRes = await ClientServices.fetchClients(userInfo);
    dispatch(setLoading(false));
    if (clientRes.clients && clientRes.clients.length > 0) {
      return dispatch(setClients(clientRes.clients));
    }
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(receiveErrors(error));
    throw error;
  }
};

export const addClient = (clientInfo) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(clearErrors());
    const clientRes = await ClientServices.addClient(clientInfo);
    dispatch(setLoading(false));
    return clientRes;
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(retrieveErrors(error));
    throw error;
  }
};
