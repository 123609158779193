import TaskActionTypes from './task.types';

const INITIAL_STATE = {
  tasks: []
};

const taskReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TaskActionTypes.SET_TASKS:
      return {
        ...state,
        tasks: action.payload
      };
    case TaskActionTypes.UPDATE_TASK: {
      let taskState = [...state.tasks];
      let taskIndex = taskState.findIndex(
        (ele) => ele.task_id == action.payload[0].task_id
      );
      if (taskIndex > -1) {
        taskState[taskIndex].assignments = action.payload;
      }
      return {
        ...state,
        tasks: taskState
      };
    }
    default:
      return state;
  }
};

export default taskReducer;
