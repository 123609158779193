/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import {
  Avatar,
  Link,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import html2canvas from 'html2canvas';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLoading } from 'src/redux/user/user.actions';
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Bar,
  ResponsiveContainer,
  Cell,
  BarChart,
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  CartesianGrid,
  XAxis,
  YAxis,
  LabelList,
  LineChart,
  Line
} from 'recharts';

const SectionSummary = (props) => {
  const dispatch = useDispatch();
  const maxScore = 5;
  const showLoading = useSelector((state) => state.user.loading);
  const sectionSummaryInfo = props.sectionSummary;
  const [selectedChartType, setSelectedChartType] = useState('self_image');

  const CustomLabel = (text) => {
    return (
      <Typography
        variant="h6"
        align="center"
        style={{ wordWrap: 'break-word' }}
      >
        {text}
      </Typography>
    );
  };

  const renderCustomizedLabel = (props) => {
    const x = 150,
      y = 200,
      width = 10,
      height = 10,
      value = 1.0;

    const fireOffset = value.toString().length < 5;
    const offset = fireOffset ? -40 : 5;
    return (
      <text
        x={x + width - offset}
        y={y + height - 5}
        fill={fireOffset ? '#285A64' : '#fff'}
        textAnchor="end"
      >
        {value}
      </text>
    );
  };

  return (
    <Grid container xs={12} sm={12} justifyContent="center">
      <Grid item>
        <Grid container xs={12} sm={12} style={{ marginBottom: 20 }}>
          <Grid item xs={10} sm={10}>
            <Typography gutterBottom variant="h6">
              <Link
                href="#"
                onClick={() => {
                  setSelectedChartType('self_image');
                }}
              >
                {`Self Image | `}
              </Link>
              <Link
                href="#"
                onClick={() => {
                  setSelectedChartType('self_image_table');
                }}
              >
                {`Self Image Table | `}
              </Link>
              <Link
                href="#"
                onClick={() => {
                  setSelectedChartType('c_bar');
                }}
              >
                {`C-Bar | `}
              </Link>
              <Link
                href="#"
                onClick={() => {
                  setSelectedChartType('table');
                }}
              >
                {`Table `}
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2}>
            <Typography gutterBottom variant="h6">
              <Link
                href="#"
                onClick={() => {
                  html2canvas(document.getElementById(selectedChartType)).then(
                    function (canvas) {
                      canvas.toBlob(function (blob) {
                        navigator.clipboard
                          .write([
                            new ClipboardItem(
                              Object.defineProperty({}, blob.type, {
                                value: blob,
                                enumerable: true
                              })
                            )
                          ])
                          .then(function () {
                            // domNode.classList.remove('on');
                          });
                      });
                    }
                  );
                }}
              >
                {`Copy`}
              </Link>
            </Typography>
          </Grid>
        </Grid>

        {/* <Grid container alignItems="center" justifyContent="center">
              <Grid item>
                <Typography color="primary" gutterBottom variant="h2">
                  {personalSummaryInfo.type}
                </Typography>
              </Grid>
            </Grid> */}

        <Grid
          container
          alignItems="center"
          xs={12}
          sm={12}
          justifyContent="center"
        >
          {selectedChartType == 'self_image' && (
            <Grid
              item
              id="self_image"
              style={{
                borderColor: '#D8D8D8',
                borderWidth: 0,
                borderStyle: 'ridge'
              }}
            >
              <div>
                <RadarChart
                  cx={500}
                  cy={250}
                  outerRadius={200}
                  width={1000}
                  height={500}
                  data={sectionSummaryInfo}
                >
                  <PolarGrid stroke="#f4f4f4" gridType="polygon" />
                  <PolarAngleAxis
                    type="category"
                    dataKey="section_code"
                    position="outside"
                    style={{
                      margin: 20,
                      fontSize: 14,
                      fontFamily: 'Arial',
                      width: '50px',
                      wordWrap: 'break-word'
                    }}
                  />
                  <PolarRadiusAxis
                    tick={{ fontSize: 14 }}
                    interval={6}
                    scale="auto"
                    angle={0}
                    domain={[0, 4]}
                    style={{
                      margin: 20,
                      fontSize: 14,
                      fontFamily: 'Arial',
                      width: '50px',
                      wordWrap: 'break-word'
                    }}
                  />
                  <Radar
                    dot="false"
                    name="Self"
                    dataKey="self_val"
                    stroke="#508ecc"
                    fill="#f4f4f4"
                    strokeWidth="3"
                    fillOpacity={0.6}
                  >
                    <LabelList
                      offset={10}
                      position="left"
                      dataKey="self_val"
                      angle={360}
                      style={{
                        fontSize: 14,
                        fontFamily: 'Arial'
                      }}
                    />
                  </Radar>
                  <Radar
                    dot="false"
                    legendType="circle"
                    name="Others"
                    dataKey="total_avg"
                    stroke="#ec7133"
                    strokeWidth="3"
                    fill="#f4f4f4"
                    fillOpacity={0.1}
                  >
                    <LabelList
                      offset={10}
                      position="right"
                      dataKey="total_avg"
                      angle="360"
                      style={{ fontSize: 14, fontFamily: 'Arial' }}
                    />
                  </Radar>
                  <Legend
                    wrapperStyle={{ fontSize: 14, fontFamily: 'Arial' }}
                  />
                  <Tooltip />
                </RadarChart>
              </div>
            </Grid>
          )}
          {selectedChartType == 'c_bar' && (
            <div>
              <Grid
                item
                id="c_bar"
                style={{
                  borderColor: '#D8D8D8',
                  borderWidth: 0,
                  borderStyle: 'ridge'
                }}
              >
                <BarChart
                  width={850}
                  height={440}
                  data={sectionSummaryInfo}
                  margin={{
                    top: 25,
                    right: 30,
                    left: 20,
                    bottom: 5
                  }}
                >
                  {/* <CartesianGrid strokeDasharray="4" /> */}
                  <XAxis
                    dataKey="section_code"
                    style={{ fontSize: 12 }}
                    interval={0}
                    tick={{
                      fontSize: '12px',
                      width: '70px',
                      height: '100px',
                      wordWrap: 'break-word',
                      fontFamily: 'Arial'
                    }}
                  />
                  <YAxis
                    domain={[0.0, 4.0]}
                    tickCount={9}
                    tick={{
                      fontSize: '14px',
                      fontFamily: 'Arial'
                    }}
                  />
                  <Tooltip />
                  <Bar name="Self" dataKey="self_val" fill="#508ecc">
                    <LabelList
                      dataKey="self_val"
                      position="top"
                      style={{ fontSize: 10, fontFamily: 'Arial' }}
                    />
                  </Bar>
                  <Bar name="IO" dataKey="io_avg" fill="#ec7133">
                    <LabelList
                      dataKey="io_avg"
                      position="top"
                      style={{ fontSize: 10, fontFamily: 'Arial' }}
                    />
                  </Bar>
                  <Bar name="Peer" dataKey="peer_avg" fill="#737373">
                    <LabelList
                      dataKey="peer_avg"
                      position="top"
                      style={{ fontSize: 10, fontFamily: 'Arial' }}
                    />
                  </Bar>
                  <Bar
                    name="Sub-Ordinate"
                    dataKey="subordinate_avg"
                    fill="#ffb82a"
                  >
                    <LabelList
                      dataKey="subordinate_avg"
                      position="top"
                      style={{ fontSize: 10, fontFamily: 'Arial' }}
                    />
                  </Bar>
                  <Legend
                    wrapperStyle={{
                      paddingTop: '50px',
                      fontSize: 14,
                      fontFamily: 'Arial'
                    }}
                    // layout="horizontal"
                    // verticalAlign="bottom"
                    // align="center"
                  />
                </BarChart>
              </Grid>
            </div>
          )}
          {selectedChartType == 'table' && (
            <Grid item>
              <TableContainer
                style={{ marginTop: 25 }}
                component={Paper}
                id="table"
              >
                <Table aria-label="simple table" width={850}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Competency</TableCell>
                      <TableCell align="right">Self</TableCell>
                      <TableCell align="right">IO Avg</TableCell>
                      <TableCell align="right">Peer Avg</TableCell>
                      <TableCell align="right">SubOrdinate Avg</TableCell>
                      <TableCell align="right">Total Avg</TableCell>
                      <TableCell align="right">Gap</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sectionSummaryInfo.map((section) => (
                      <TableRow key={section.section_code}>
                        <TableCell component="th" scope="row">
                          {section.section_code}
                        </TableCell>
                        <TableCell align="right">{section.self_val}</TableCell>
                        <TableCell align="right">{section.io_avg}</TableCell>
                        <TableCell align="right">{section.peer_avg}</TableCell>
                        <TableCell align="right">
                          {section.subordinate_avg}
                        </TableCell>
                        <TableCell align="right">{section.total_avg}</TableCell>
                        <TableCell align="right">{section.gap}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
          {selectedChartType == 'self_image_table' && (
            <Grid item>
              <TableContainer
                style={{ marginTop: 25 }}
                component={Paper}
                id="self_image_table"
              >
                <Table aria-label="simple table" width={850}>
                  <TableHead>
                    <TableRow>
                      <TableCell>Competency</TableCell>
                      <TableCell align="right">Self</TableCell>
                      <TableCell align="right">Others</TableCell>
                      <TableCell align="right">Gap</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sectionSummaryInfo.map((section) => (
                      <TableRow key={section.section_code}>
                        <TableCell component="th" scope="row">
                          {section.section_code}
                        </TableCell>
                        <TableCell align="right">{section.self_val}</TableCell>
                        <TableCell align="right">{section.total_avg}</TableCell>
                        <TableCell align="right">{section.gap}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SectionSummary;
