import LoadingOverlay from 'react-loading-overlay';

const Spinner = (props) => {
  const isLoading = props.showLoading;
  return (
    <>
      <LoadingOverlay
        styles={{
          spinner: (base) => ({
            ...base,
            width: '75px',
            '& svg circle': {
              stroke: 'rgba(128,0,128, 10)'
            }
          })
        }}
        active={isLoading}
        spinner
      />
    </>
  );
};

export default Spinner;
