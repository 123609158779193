import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Divider,
  Select,
  InputLabel,
  MenuItem
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import Spinner from 'src/components/Spinner';
import toast, { Toaster } from 'react-hot-toast';
import { setLoading } from 'src/redux/user/user.actions';
import {
  addClientUser,
  getClientUsers
} from 'src/redux/client-users/clientuser.actions';
import UsersListToolbar from 'src/components/users/UsersListToolbar';
import UserResults from 'src/components/users/UserResults';
import { getClients } from 'src/redux/client/client.actions';

const ClientUsers = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const errorState = useSelector((state) => state.errors);
  const showLoading = useSelector((state) => state.user.loading);
  const clientsState = useSelector((state) => state.client.clients);
  const clientUserssState = useSelector(
    (state) => state.clientuser.clientusers
  );
  const [isAddUser, setIsAddUser] = useState(false);
  const [clientUsersLocalState, setClientUsersLocalState] =
    useState(clientUserssState);
  const [selectedClient, setSelectedClient] = useState(null);
  const [saveSelectedClient, setSaveSelectedClient] = useState(null);

  const receiveRefresh = () => {
    if (selectedClient != null) {
      dispatch(setLoading(true));
      dispatch(getClientUsers({ clientId: selectedClient }));
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (selectedClient != null) {
      dispatch(setLoading(true));
      dispatch(getClientUsers({ clientId: selectedClient }));
      dispatch(setLoading(false));
    }
  }, [selectedClient]);

  useEffect(() => {
    setClientUsersLocalState(clientUserssState);
  }, [clientUserssState]);

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getClients({ email: user.email }));
    dispatch(setLoading(false));
  }, []);

  return (
    <>
      <Helmet>
        <title>Users</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Toaster position="top-center" reverseOrder={false} />
          <Spinner showLoading={showLoading} />
          {!isAddUser ? (
            <>
              <UsersListToolbar
                handleAddClick={() => setIsAddUser(true)}
                clients={clientsState}
                handleRefresh={receiveRefresh}
                handleClientChange={(val) => setSelectedClient(val)}
              />
              <Box sx={{ pt: 3 }}>
                <UserResults users={clientUsersLocalState} />
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ pt: 3 }}>
                <Grid item lg={12} md={12} xs={12}>
                  <Card mx={2}>
                    <CardHeader subheader="Create new user" title="User" />
                    <Divider />
                    <CardContent>
                      <Formik
                        initialValues={{
                          firstName: '',
                          lastName: '',
                          email: '',
                          phone: '',
                          designation: ''
                        }}
                        validationSchema={Yup.object().shape({
                          firstName: Yup.string()
                            .min(3)
                            .max(255)
                            .required(
                              'First Name is required. Minimum 3 characters'
                            ),
                          lastName: Yup.string()
                            .min(1)
                            .max(255)
                            .required(
                              'Last Name is required. Minimum 1 character'
                            ),
                          email: Yup.string()
                            .email('Must be a valid email')
                            .max(255)
                            .required('Email is required')
                        })}
                        onSubmit={(values) => {
                          values.clientId = selectedClient;
                          dispatch(addClientUser(values))
                            .then(() => {
                              setIsAddUser(false);
                              toast.success(
                                'User record got saved successfully'
                              );
                            })
                            .catch((err) => {});
                        }}
                      >
                        {({
                          errors,
                          handleBlur,
                          handleSubmit,
                          handleChange,
                          touched,
                          values
                        }) => (
                          <form onSubmit={handleSubmit}>
                            <Grid container spacing={3}>
                              {/* <Grid item md={6} xs={12}>
                                <InputLabel
                                  style={{ marginLeft: 5, fontSize: 12 }}
                                  htmlFor="Select Client"
                                >
                                  Select Client
                                </InputLabel>
                                <Select
                                  fullWidth
                                  onChange={(event) => {
                                    setSaveSelectedClient(event.target.value);
                                  }}
                                  required
                                  SelectProps={{ native: true }}
                                  variant="outlined"
                                >
                                  {clientsState.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                      {option.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Grid> */}
                              <Grid item md={6} xs={12}>
                                <TextField
                                  error={Boolean(
                                    touched.firstName && errors.firstName
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.firstName && errors.firstName
                                  }
                                  label="First Name"
                                  margin="normal"
                                  name="firstName"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="text"
                                  value={values.firstName}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <TextField
                                  error={Boolean(
                                    touched.lastName && errors.lastName
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.lastName && errors.lastName
                                  }
                                  label="Last Name"
                                  margin="normal"
                                  name="lastName"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="text"
                                  value={values.lastName}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <TextField
                                  error={Boolean(touched.email && errors.email)}
                                  fullWidth
                                  helperText={touched.email && errors.email}
                                  label="Email Address"
                                  margin="normal"
                                  name="email"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="email"
                                  value={values.email}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <TextField
                                  error={Boolean(touched.phone && errors.phone)}
                                  fullWidth
                                  helperText={touched.phone && errors.phone}
                                  label="Phone Number"
                                  margin="normal"
                                  name="phone"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="tel"
                                  value={values.phone}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item md={6} xs={12}>
                                <TextField
                                  error={Boolean(
                                    touched.designation && errors.designation
                                  )}
                                  fullWidth
                                  helperText={
                                    touched.designation && errors.designation
                                  }
                                  label="Designation"
                                  margin="normal"
                                  name="designation"
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  type="text"
                                  value={values.designation}
                                  variant="outlined"
                                />
                              </Grid>
                            </Grid>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                p: 2
                              }}
                            >
                              <Button
                                onClick={() => setIsAddUser(false)}
                                color="secondary"
                                size="large"
                                type="submit"
                                variant="contained"
                              >
                                CANCEL
                              </Button>
                              <Button
                                color="primary"
                                size="large"
                                type="submit"
                                variant="contained"
                              >
                                SAVE
                              </Button>
                            </Box>
                            <Typography
                              color="red"
                              variant="body1"
                              align="center"
                            >
                              {errorState &&
                                errorState.info &&
                                errorState.info.msg}
                            </Typography>
                          </form>
                        )}
                      </Formik>
                    </CardContent>
                  </Card>
                </Grid>
              </Box>
            </>
          )}
        </Container>
      </Box>
    </>
  );
};

export default ClientUsers;
