/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable quotes */
/* eslint-disable react/jsx-curly-brace-presence */
import { useEffect, useState, useRef } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  FormControl,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  Typography,
  Button,
  TextField,
  Radio
} from '@material-ui/core';
import * as _ from 'lodash';
import toast, { Toaster } from 'react-hot-toast';
import { useSelector, useDispatch } from 'react-redux';
import {
  setAnswer,
  removeAnswer
} from 'src/redux/assessment/assessment.action';
import { setLoading } from 'src/redux/user/user.actions';
import { submitAnswers } from 'src/services/AssessmentService';

const Reflection_Questionnaire = (props) => {
  const dispatch = useDispatch();
  const headerRef = useRef();
  const participantName = props.participantname;
  const assessorName = props.assessorname;
  const relation = props.relation;
  const questions = props.questionList;
  const totalPageCount = Math.floor(questions.length / 10);
  const answersState = useSelector((state) => state.assessment.answerList);
  const offsetLimit = 10;
  const [limit, setLimit] = useState({
    startVal: 0,
    prevVal: 0,
    limit: questions.length,
    offset: offsetLimit
  });
  const [questionList, setQuestionList] = useState([]);
  const [currentPageStartVal, setCurrentPageStartVal] = useState(0);
  const [error, setError] = useState('');

  const addToAnswerList = (event, question) => {
    let quesList = [...questionList];
    const ansIndex = answersState.findIndex(
      (ans) => ans.question_id === question.question_id
    );
    const quesIndex = quesList.findIndex(
      (ques) => ques.question_id === question.question_id
    );
    if (ansIndex > -1) {
      answersState[ansIndex].answer = event.target.value;
    } else {
      const newVal = {
        taskId: question.task_id,
        assignmentId: question.assignment_id,
        questionId: question.question_id,
        answer: event.target.value,
        clientId: question.client_id
      };
      dispatch(setAnswer(newVal));
    }
    if (quesIndex > -1) {
      quesList[quesIndex].option_desc.forEach((option) => {
        if (option.key == event.target.value) {
          option.checked = true;
        } else {
          option.checked = false;
        }
      });
      setQuestionList(quesList);
    }
  };

  const handleRadioChange = (event, question) => {
    let notSureCount = answersState.filter((ans) => ans.answer == 0);
    if (relation == 'Self') {
      if (event.target.value == 0) {
        toast.error('You cannot choose "Not Sure" as a response for self');
      } else {
        addToAnswerList(event, question);
      }
    } else {
      if (notSureCount.length < 8) {
        notSureCount = notSureCount + 1;
        addToAnswerList(event, question);
      } else if (notSureCount.length >= 8 && event.target.value != 0) {
        addToAnswerList(event, question);
      } else {
        toast.error('You cannot choose more than 8 "Not Sure" response');
      }
    }
  };

  const handleTextChange = (event, question) => {
    let newValue = event.target.value.trim();
    if (newValue.length > 0) {
      if (!newValue.match(/[%<>\\$'"]/)) {
        setError('');
        const newVal = {
          taskId: question.task_id,
          assignmentId: question.assignment_id,
          questionId: question.question_id,
          answer: event.target.value,
          clientId: question.client_id
        };
        dispatch(setAnswer(newVal));
      } else {
        setError('Special Characters %<>$\'" not allowed');
      }
    } else {
      setError('');
      dispatch(removeAnswer(question.question_id));
    }
  };

  const moveToPrevQues = () => {
    setCurrentPageStartVal(currentPageStartVal - offsetLimit);
  };

  const moveToNextQues = () => {
    if (answersState.length >= currentPageStartVal + offsetLimit) {
      setCurrentPageStartVal(currentPageStartVal + offsetLimit);
    } else {
      toast.error('Please answer all the questions before submit');
    }
  };

  const submitResponse = async () => {
    try {
      if (answersState.length >= currentPageStartVal + 4 && error == '') {
        dispatch(setLoading(true));
        const feedbackRes = await submitAnswers(answersState);
        props.handleSubmit();
        dispatch(setLoading(false));
      } else {
        toast.error(
          'Please answer all the questions before submit or check for special characters in the answer you typed'
        );
      }
    } catch (error) {
      dispatch(setLoading(false));
      console.log(error);
    }
  };

  useEffect(() => {
    setQuestionList(
      questions.slice(currentPageStartVal, currentPageStartVal + limit.offset)
    );
    headerRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [currentPageStartVal]);

  useEffect(() => {
    if (props.questionList.length > 0) {
      setQuestionList(
        questions.slice(limit.startVal, limit.startVal + limit.offset)
      );
      // let newStart = limit.startVal + offsetLimit;
      // setLimit({ ...limit, startVal: newStart });
    }
  }, [props.questionList]);

  return (
    <>
      <Grid container spacing={6} wrap="wrap" ref={headerRef}>
        <Grid item lg={6} md={6} sm={6} sx={12} xs={12}>
          <Typography
            style={styles.nameStyle}
            color="textPrimary"
            gutterBottom
            variant="body1"
          >
            {`Name : ${participantName}`}
          </Typography>
          <Typography
            style={styles.nameStyle}
            color="textPrimary"
            gutterBottom
            variant="body1"
          >
            {/* {`Your Name : ${assessorName}`} */}
          </Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={6} sx={12} xs={12}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: 10
            }}
          >
            <img
              alt="Logo"
              width={70}
              height={55}
              src="/static/logo.png"
              {...props}
            />
          </Box>
        </Grid>
      </Grid>
      <Toaster position="top-center" reverseOrder={false} />
      <Card style={styles.cardStyle}>
        <CardHeader
          color="primary"
          title="Self-Reflection"
          style={styles.cardHeaderStyle}
        />
        <Divider />
        <CardContent style={styles.cardContentStyle}>
          {questionList.map((question) => {
            return (
              <Box style={{ marginTop: 20 }} key={question.question_id}>
                {question.answer_type == 'option' ? (
                  <FormControl component="fieldset">
                    <FormLabel component="legend">{`${question.sequence}. ${question.question_desc}`}</FormLabel>
                    <RadioGroup
                      row
                      aria-label="assessment"
                      name="assessment"
                      value=""
                      onChange={(event) => handleRadioChange(event, question)}
                    >
                      {question.option_desc.map((option) => {
                        return (
                          <FormControlLabel
                            key={option.key}
                            value={option.key}
                            control={
                              <Radio
                                size="small"
                                color="primary"
                                checked={option.checked}
                              />
                            }
                            label={option.val}
                          />
                        );
                      })}
                    </RadioGroup>
                  </FormControl>
                ) : (
                  <FormControl component="fieldset">
                    <FormLabel component="legend">{`${question.sequence}. ${question.question_desc}`}</FormLabel>
                    <TextField
                      style={{ marginTop: 15, width: '100%' }}
                      id={question.question_id.toString()}
                      onChange={(event) => handleTextChange(event, question)}
                      label="Type your answer"
                      helperText={error}
                      error={!!error}
                      multiline
                      fullWidth
                      rows={5}
                    />
                  </FormControl>
                )}
              </Box>
            );
          })}
        </CardContent>
        <Divider />
        {Math.round((currentPageStartVal + offsetLimit) / 10) <=
        totalPageCount ? (
          <div style={styles.footerButtonContainer}>
            {currentPageStartVal >= 10 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                <Button
                  style={styles.buttonStyle}
                  color="primary"
                  variant="contained"
                  onClick={moveToPrevQues}
                >
                  PREVIOUS
                </Button>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
              }}
            >
              <Button
                style={styles.buttonStyle}
                color="primary"
                variant="contained"
                onClick={moveToNextQues}
              >
                NEXT
              </Button>
            </Box>
          </div>
        ) : (
          <div style={styles.footerButtonContainer}>
            {currentPageStartVal >= 10 && (
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                <Button
                  style={styles.buttonStyle}
                  color="primary"
                  variant="contained"
                  onClick={moveToPrevQues}
                >
                  PREVIOUS
                </Button>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
              }}
            >
              <Button
                style={styles.buttonStyle}
                color="primary"
                variant="contained"
                onClick={submitResponse}
              >
                SUBMIT
              </Button>
            </Box>
          </div>
        )}
      </Card>
    </>
  );
};

const styles = {
  nameStyle: {
    margin: 10
  },
  cardStyle: {
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.35)',
    marginTop: 5,
    marginBottom: 20,
    borderRadius: 10
  },
  cardContentStyle: {
    marginLeft: 10
  },
  radioGroup: {
    marginLeft: 20
  },
  cardHeaderStyle: {
    color: '#f77445',
    backgroundColor: '#f4f4f4',
    height: 70
  },
  footerButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  buttonStyle: {
    width: 100
  }
};

export default Reflection_Questionnaire;
