import * as TaskServcices from 'src/services/TaskService';
import { receiveErrors, clearErrors } from '../error/error.actions';
import { setLoading } from '../user/user.actions';
import TaskActionTypes from './task.types';

const setTasks = (tasks) => ({
  type: TaskActionTypes.SET_TASKS,
  payload: tasks
});

const updateTask = (task) => ({
  type: TaskActionTypes.UPDATE_TASK,
  payload: task
});

export const getTasks = (clientInfo) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(clearErrors());
    const taskRes = await TaskServcices.fetchTasks(clientInfo);
    if (taskRes && taskRes.tasks && taskRes.tasks.length > 0) {
      let taskResponse = taskRes.tasks;
      taskResponse.forEach((task) => {
        task.assignments = [];
      });
      dispatch(setLoading(false));
      return dispatch(setTasks(taskResponse));
    } else {
      return dispatch(setTasks([]));
    }
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(receiveErrors(error));
    throw error;
  }
};

export const getAssignments = (taskInfo) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(clearErrors());
    const assignmentRes = await TaskServcices.fetchTaskAssignments(taskInfo);
    if (
      assignmentRes &&
      assignmentRes.assignments &&
      assignmentRes.assignments.length > 0
    ) {
      dispatch(setLoading(false));
      return dispatch(updateTask(assignmentRes.assignments));
    }
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(receiveErrors(error));
    throw error;
  }
};

export const addTask = (taskInfo) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(clearErrors());
    const taskRes = await TaskServcices.addTask(taskInfo);
    dispatch(setLoading(false));
    return taskRes;
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(retrieveErrors(error));
    throw error;
  }
};

export const addAssignment = (assignmentInfo) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(clearErrors());
    const assignmentRes = await TaskServcices.addAssignment(assignmentInfo);
    dispatch(setLoading(false));
    return assignmentRes;
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(retrieveErrors(error));
    throw error;
  }
};

export const calcScore = (scoreObj) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(clearErrors());
    const calcRes = await TaskServcices.calculateScoreService(scoreObj);
    dispatch(setLoading(false));
    return assignmentRes;
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(retrieveErrors(error));
    throw error;
  }
};
