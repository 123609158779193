import DashboardActionTypes from './dashboard.types';

const INITIAL_STATE = {
  totalInfo: null,
  clients: [],
  participants: [],
  feedbacks: [],
  personalAssessment: [],
  teamProfile: null,
  sectionSummary: [],
  questionSummary: [],
  qualitativeSummary: null
};

const dashboardReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DashboardActionTypes.SET_CLIENTS:
      return {
        ...state,
        clients: action.payload
      };
    case DashboardActionTypes.SET_TOTAL_INFO:
      return {
        ...state,
        totalInfo: action.payload
      };
    case DashboardActionTypes.SET_PARTICIPANTS:
      return {
        ...state,
        participants: action.payload
      };
    case DashboardActionTypes.SET_FEEDBACK_SUMMARY:
      return {
        ...state,
        feedbacks: action.payload
      };
    case DashboardActionTypes.SET_PERSONAL_ASSESSMENT:
      return {
        ...state,
        personalAssessment: action.payload
      };
    case DashboardActionTypes.SET_TEAM_PROFILE:
      return {
        ...state,
        teamProfile: action.payload
      };
    case DashboardActionTypes.SET_SECTION_SUMMARY:
      return {
        ...state,
        sectionSummary: action.payload
      };
    case DashboardActionTypes.SET_QUESTION_SUMMARY:
      return {
        ...state,
        questionSummary: action.payload
      };
    case DashboardActionTypes.SET_QUALITATIVE_SUMMARY:
      return {
        ...state,
        qualitativeSummary: action.payload
      };
    case DashboardActionTypes.RESET_SUMMARY_DATA:
      return {
        ...state,
        questionSummary: [],
        sectionSummary: [],
        personalAssessment: [],
        qualitativeSummary: null,
        teamProfile: null
      };
    default:
      return state;
  }
};

export default dashboardReducer;
