function ApplicationError(code, info, message, fileName, lineNumber) {
  const instance = new Error(message, fileName, lineNumber);
  instance.name = 'ApplicationError';
  instance.code = code;
  instance.info = info;
  Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
  if (Error.captureStackTrace) {
    Error.captureStackTrace(instance, ApplicationError);
  }
  return instance;
}

ApplicationError.prototype = Object.create(Error.prototype, {
  constructor: {
    value: Error,
    enumerable: false,
    writable: true,
    configurable: true
  }
});

if (Object.setPrototypeOf) {
  Object.setPrototypeOf(ApplicationError, Error);
} else {
  // eslint-disable-next-line no-proto
  ApplicationError.__proto__ = Error;
}

export default ApplicationError;
