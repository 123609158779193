/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable quotes */
/* eslint-disable react/jsx-curly-brace-presence */
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  Box,
  Button
} from '@material-ui/core';

const PA_Instructions = (props) => {
  const assessmentType = props.assessmentType;
  const participantName = props.participantname;
  const assessorName = props.assessorname;
  const relation = props.relation;
  return (
    <>
      <Grid container spacing={6} wrap="wrap">
        <Grid item lg={6} md={6} sm={6} sx={12} xs={12}>
          <Typography
            color="textPrimary"
            gutterBottom
            variant="body1"
            style={styles.nameStyle}
          >
            {`Hi ${participantName},`}
          </Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={6} sx={12} xs={12}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: 10
            }}
          >
            <img
              alt="Logo"
              width={70}
              height={55}
              src="/static/logo.png"
              {...props}
            />
          </Box>
        </Grid>
      </Grid>
      <Card style={styles.cardStyle}>
        <CardHeader
          color="primary"
          title="Instructions"
          style={styles.cardHeaderStyle}
        />
        <Divider />
        <CardContent style={styles.cardContentStyle}>
          <Grid container spacing={6} wrap="wrap">
            <Grid item md={12} sm={12} sx={12} xs={12}>
              <Typography color="textPrimary" variant="body2">
                {`Please read the instructions carefully before you start:`}
              </Typography>
              <ul style={styles.listWrapInsStyle}>
                <li style={styles.listInsStyle}>
                  <Typography color="textPrimary" variant="body2">
                    {`Take the assessment at one shot without disturbance`}
                  </Typography>
                </li>
                <li style={styles.listInsStyle}>
                  <Typography color="textPrimary" variant="body2">
                    {`There are no right answers to any of these questions. Every personality has its own advantage and disadvantage`}
                  </Typography>
                </li>
                <li style={styles.listInsStyle}>
                  <Typography color="textPrimary" variant="body2">
                    {`Answer the questions quickly, do not over-analyze them`}
                  </Typography>
                </li>
                <li style={styles.listInsStyle}>
                  <Typography color="textPrimary" variant="body2">
                    {`Try to answer the questions without the pressure of your official or personal life`}
                  </Typography>
                </li>
                <li style={styles.listInsStyle}>
                  <Typography color="textPrimary" variant="body2">
                    {`When both answers suit / does not suit; then chose whichever comes closest to describing you`}
                  </Typography>
                </li>
                <li style={styles.listInsStyle}>
                  <Typography color="textPrimary" variant="body2">
                    {`Answer the questions as “the way you are”, not “the way you want to be or the way you’d like to be seen by others”`}
                  </Typography>
                </li>
                <li style={styles.listInsStyle}>
                  <Typography color="textPrimary" variant="body2">
                    {`Don’t use a dictionary or ask for meaning while taking the test. If you don’t understand the question, you can skip the question but don’t skip more than 8 questions.`}
                  </Typography>
                </li>
              </ul>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={props.handleStartClick}
          >
            Start
          </Button>
        </Box>
      </Card>
    </>
  );
};

const styles = {
  nameStyle: {
    margin: 10
  },
  cardStyle: {
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.35)',
    borderRadius: 10
  },
  cardContentStyle: {
    marginLeft: 10
  },
  listWrapInsStyle: {
    marginLeft: 20
  },
  listInsStyle: {
    paddingTop: 20
  },
  cardHeaderStyle: {
    color: '#f77445',
    backgroundColor: '#f4f4f4'
  }
};

export default PA_Instructions;
