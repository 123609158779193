import * as DashboardServices from 'src/services/DashboardServices';
import { clearErrors, receiveErrors } from '../error/error.actions';
import DashboardActionTypes from './dashboard.types';

const setTotalInfo = (totalInfo) => ({
  type: DashboardActionTypes.SET_TOTAL_INFO,
  payload: totalInfo
});

const setClients = (clients) => ({
  type: DashboardActionTypes.SET_CLIENTS,
  payload: clients
});

const setParticipants = (participants) => ({
  type: DashboardActionTypes.SET_PARTICIPANTS,
  payload: participants
});

const setFeedbackSummary = (feedbackSummary) => ({
  type: DashboardActionTypes.SET_FEEDBACK_SUMMARY,
  payload: feedbackSummary
});

const setPersonalAssessment = (personalReport) => ({
  type: DashboardActionTypes.SET_PERSONAL_ASSESSMENT,
  payload: personalReport
});

const setTeamProfile = (teamProfile) => ({
  type: DashboardActionTypes.SET_TEAM_PROFILE,
  payload: teamProfile
});

const setSectionSummary = (sectionSummary) => ({
  type: DashboardActionTypes.SET_SECTION_SUMMARY,
  payload: sectionSummary
});

const setQuestionSummary = (questionSummary) => ({
  type: DashboardActionTypes.SET_QUESTION_SUMMARY,
  payload: questionSummary
});

const setQualitativeSummary = (qualitativeSummary) => ({
  type: DashboardActionTypes.SET_QUALITATIVE_SUMMARY,
  payload: qualitativeSummary
});

export const resetSummaryData = () => ({
  type: DashboardActionTypes.RESET_SUMMARY_DATA,
  payload: null
});

export const totalInfo = (user) => async (dispatch) => {
  try {
    dispatch(clearErrors());
    const totalInfoRes = await DashboardServices.fetchTotalInfo(user);
    if (totalInfoRes.totalInfo && totalInfoRes.totalInfo.length > 0) {
      return dispatch(setTotalInfo(totalInfoRes.totalInfo[0]));
    }
    return dispatch(
      setTotalInfo({
        client_count: 0,
        person_count: 0,
        assignment_count: 0,
        feedback_count: 0
      })
    );
  } catch (error) {
    return dispatch(receiveErrors(error));
  }
};

export const fetchClients = (client) => async (dispatch) => {
  try {
    dispatch(clearErrors());
    const clientRes = await DashboardServices.fetchAllClients(client);
    if (clientRes.clients && clientRes.clients.length > 0) {
      return dispatch(setClients(clientRes.clients));
    }
    return dispatch(setClients([]));
  } catch (error) {
    return dispatch(receiveErrors(error));
  }
};

export const fetchParticipants = (client) => async (dispatch) => {
  try {
    dispatch(clearErrors());
    const participantRes = await DashboardServices.fetchParticipants(client);
    return dispatch(setParticipants(participantRes));
  } catch (error) {
    return dispatch(receiveErrors(error));
  }
};

export const fetchFeedbacks = (client) => async (dispatch) => {
  try {
    dispatch(clearErrors());
    const feedbackRes = await DashboardServices.fetchFeedbacks(client);
    if (feedbackRes.feedbackSummary && feedbackRes.feedbackSummary.length > 0) {
      return dispatch(setFeedbackSummary(feedbackRes.feedbackSummary));
    }
    return dispatch(setFeedbackSummary([]));
  } catch (error) {
    return dispatch(receiveErrors(error));
  }
};

export const fetchPersonalAssessment = (paObj) => async (dispatch) => {
  try {
    dispatch(clearErrors);
    const PAResponse = await DashboardServices.fetchPersonalAssessmentService(
      paObj
    );
    if (PAResponse.personalReport && PAResponse.personalReport.length > 0) {
      return dispatch(setPersonalAssessment(PAResponse.personalReport));
    }
    return dispatch(setPersonalAssessment([]));
  } catch (error) {
    return dispatch(receiveErrors(error));
  }
};

export const fetchTeamProfile = (teamObj) => async (dispatch) => {
  try {
    dispatch(clearErrors);
    const teamProfileResponse = await DashboardServices.fetchTeamProfileService(
      teamObj
    );
    if (teamProfileResponse && teamProfileResponse.teamProfileRes) {
      return dispatch(setTeamProfile(teamProfileResponse.teamProfileRes));
    }
  } catch (error) {
    return dispatch(receiveErrors(error));
  }
};

export const fetchSectionSummary = (sectionObj) => async (dispatch) => {
  try {
    dispatch(clearErrors);
    const sectionSummaryResponse =
      await DashboardServices.fetchSectionSummaryService(sectionObj);
    if (
      sectionSummaryResponse &&
      sectionSummaryResponse.sectionSummaryRes.length > 0
    ) {
      return dispatch(
        setSectionSummary(sectionSummaryResponse.sectionSummaryRes)
      );
    }
  } catch (error) {
    return dispatch(receiveErrors(error));
  }
};

export const fetchQuestionSummary = (quesObj) => async (dispatch) => {
  try {
    dispatch(clearErrors);
    const questionSummaryResponse =
      await DashboardServices.fetchQuestionSummaryService(quesObj);
    if (
      questionSummaryResponse &&
      questionSummaryResponse.questionSummaryRes.length > 0
    ) {
      return dispatch(
        setQuestionSummary(questionSummaryResponse.questionSummaryRes)
      );
    }
  } catch (error) {
    return dispatch(receiveErrors(error));
  }
};

export const fetchQualitativeSummary = (qualObj) => async (dispatch) => {
  try {
    dispatch(clearErrors);
    const qualitativeSummaryResponse =
      await DashboardServices.fetchQualitativeSummaryService(qualObj);
    if (
      qualitativeSummaryResponse &&
      qualitativeSummaryResponse.qualitativeSummaryRes
    ) {
      return dispatch(
        setQualitativeSummary(qualitativeSummaryResponse.qualitativeSummaryRes)
      );
    }
  } catch (error) {
    return dispatch(receiveErrors(error));
  }
};
