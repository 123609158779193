import { get } from 'lodash';
import * as UserServices from 'src/services/UserServices';
import { receiveErrors, clearErrors } from '../error/error.actions';
import { setLoading } from '../user/user.actions';
import ClientUserActionTypes from './clientuser.types';

const setClientUsers = (clientusers) => ({
  type: ClientUserActionTypes.SET_CLIENT_USERS,
  payload: clientusers
});

export const getClientUsers = (clientInfo) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(clearErrors());
    const clientUsersRes = await UserServices.getClientUsers(clientInfo);
    dispatch(setLoading(false));
    return dispatch(setClientUsers(clientUsersRes));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(receiveErrors(error));
    throw error;
  }
};

export const addClientUser = (userInfo) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(clearErrors());
    const clientUserRes = await UserServices.addClientUsers(userInfo);
    dispatch(setLoading(false));
    return clientUserRes;
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(receiveErrors(error));
    throw error;
  }
};
