import { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Select,
  MenuItem,
  IconButton,
  InputLabel
} from '@material-ui/core';
import Refresh from '@material-ui/icons/Refresh';

const TaskListToolbar = (props) => {
  const clients = props.clients;
  const [selectedVal, setSelectedVal] = useState(null);
  return (
    <Box {...props}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={props.handleAddClick}
        >
          Add Assessment
        </Button>
        <IconButton
          color="primary"
          variant="contained"
          onClick={props.handleRefresh}
        >
          <Refresh />
        </IconButton>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Box sx={{ maxWidth: 500 }}>
              <InputLabel style={{ margin: 5 }} htmlFor="Select Client">
                Select Client
              </InputLabel>
              <Select
                fullWidth
                onChange={(event) => {
                  setSelectedVal(event.target.value);
                  props.handleClientChange(event.target.value);
                }}
                required
                SelectProps={{ native: true }}
                variant="outlined"
              >
                {clients.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default TaskListToolbar;
