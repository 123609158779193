/* eslint-disable react/jsx-curly-newline */
/* eslint-disable object-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable react/prop-types */
/* eslint-disable no-else-return */
/* eslint-disable no-confusing-arrow */
/* eslint-disable arrow-body-style */
import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from 'src/components/DashboardLayout';
import MainLayout from 'src/components/MainLayout';
import Account from 'src/pages/Account';
import CustomerList from 'src/pages/CustomerList';
import Dashboard from 'src/pages/Dashboard';
import Login from 'src/pages/Login';
import NotFound from 'src/pages/NotFound';
import ProductList from 'src/pages/ProductList';
import Register from 'src/pages/Register';
import Settings from 'src/pages/Settings';
import Assessment from './pages/Assessment';
import Clients from './pages/Clients';
import ClientUsers from './pages/ClientUsers';
import Tasks from './pages/Tasks';

const routes = (user, validSession) => [
  {
    path: 'app',
    element: user && validSession ? <DashboardLayout /> : <Login />,
    children: [
      { path: 'clients', element: <Clients /> },
      { path: 'users', element: <ClientUsers /> },
      { path: 'tasks', element: <Tasks /> },
      { path: 'account', element: <Account /> },
      { path: 'customers', element: <CustomerList /> },
      { path: 'dashboard', element: <Dashboard /> },
      { path: 'products', element: <ProductList /> },
      { path: 'settings', element: <Settings /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element:
      !user && !validSession ? (
        <MainLayout />
      ) : (
        <Navigate to="/app/dashboard" />
      ),
    children: [
      { path: 'assessment/*', element: <Assessment /> },
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
