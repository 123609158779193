import { SERVER_METHODS, requestServer } from '../utils/request';
import ApplicationError from '../utils/applicationError';

export const fetchTasks = async (clientInfo) => {
  const path = `/task/fetch_tasks`;
  try {
    const res = await requestServer(SERVER_METHODS.POST, path, clientInfo);
    if (res.status === 200) {
      return await res.json();
    }
    throw new ApplicationError(res.status, await res.json());
  } catch (error) {
    if (error instanceof ApplicationError) throw error;
    else
      throw JSON.stringify({
        code: 'INT',
        info: {
          msg: 'UNKNOWN ERROR'
        }
      });
  }
};

export const addTask = async (taskInfo) => {
  const path = `/task/add_task`;
  try {
    const res = await requestServer(SERVER_METHODS.POST, path, taskInfo);
    if (res.status === 200) {
      return await res.json();
    }
    throw new ApplicationError(res.status, await res.json());
  } catch (err) {
    if (error instanceof ApplicationError) throw error;
    else
      throw JSON.stringify({
        code: 'INT',
        info: {
          msg: 'UNKNOWN ERROR'
        }
      });
  }
};

export const fetchTaskAssignments = async (taskInfo) => {
  const path = `/assignment/fetch_assignments`;
  try {
    const res = await requestServer(SERVER_METHODS.POST, path, taskInfo);
    if (res.status === 200) {
      return await res.json();
    }
    throw new ApplicationError(res.status, await res.json());
  } catch (error) {
    if (error instanceof ApplicationError) throw error;
    else
      throw JSON.stringify({
        code: 'INT',
        info: {
          msg: 'UNKNOWN ERROR'
        }
      });
  }
};

export const calculateScoreService = async (scoreObj) => {
  const path = '/feedback/calculate_score';
  try {
    const res = await requestServer(SERVER_METHODS.POST, path, scoreObj);
    if (res.status === 200) {
      return await res.json();
    }
  } catch (error) {
    if (error instanceof ApplicationError) throw error;
    else
      throw JSON.stringify({
        code: 'INT',
        info: {
          msg: 'UNKNOWN ERROR'
        }
      });
  }
};

export const addAssignment = async (assignmentInfo) => {
  const path = `/assignment/add_assignment`;
  try {
    const res = await requestServer(SERVER_METHODS.POST, path, assignmentInfo);
    if (res.status === 200) {
      return await res.json();
    }
    throw new ApplicationError(res.status, await res.json());
  } catch (error) {
    if (error instanceof ApplicationError) throw error;
    else
      throw JSON.stringify({
        code: 'INT',
        info: {
          msg: 'UNKNOWN ERROR'
        }
      });
  }
};
