import Cookies from 'js-cookie';

const AUTH_COOKIE_NAME = 'olaf_zis';
export const setAuthCookie = (val) => {
  if (val) Cookies.set(AUTH_COOKIE_NAME, val);
  else Cookies.remove(AUTH_COOKIE_NAME);
};

export const getAuthCookie = () => Cookies.get(AUTH_COOKIE_NAME);

export const delAuthCookie = (val) => {
  if (val) Cookies.remove(AUTH_COOKIE_NAME);
};
