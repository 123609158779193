import AssessmentActionTypes from './assessment.types';

export const setType = (type) => ({
  type: AssessmentActionTypes.SET_TYPE,
  payload: type
});

export const setAnswer = (answer) => ({
  type: AssessmentActionTypes.SET_ANSWER,
  payload: answer
});

export const removeAnswer = (questionId) => ({
  type: AssessmentActionTypes.REMOVE_ANSWER,
  payload: questionId
});
