import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Divider
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import ClientResults from 'src/components/clients/ClientResults';
import ClientListToolbar from 'src/components/clients/ClientsListToolbar';
import { setLoading } from 'src/redux/user/user.actions';
import { addClient, getClients } from 'src/redux/client/client.actions';
import Spinner from 'src/components/Spinner';
import toast, { Toaster } from 'react-hot-toast';

const Clients = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const errorState = useSelector((state) => state.errors);
  const showLoading = useSelector((state) => state.user.loading);
  const clientsState = useSelector((state) => state.client.clients);
  const [clientsLocalState, setClientsLocalState] = useState(clientsState);
  const [isAddClient, setIsAddClient] = useState(false);

  const receiveRefresh = () => {
    dispatch(setLoading(true));
    dispatch(getClients({ email: user.email }));
    dispatch(setLoading(false));
  };

  useEffect(() => {
    setClientsLocalState(clientsState);
  }, [clientsState]);

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(getClients({ email: user.email }));
    dispatch(setLoading(false));
  }, []);

  return (
    <>
      <Helmet>
        <title>Client</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Toaster position="top-center" reverseOrder={false} />
          <Spinner showLoading={showLoading} />
          <ClientListToolbar
            handleRefresh={receiveRefresh}
            handleAddClick={() => setIsAddClient(true)}
          />
          {!isAddClient ? (
            <Box sx={{ pt: 3 }}>
              <ClientResults clients={clientsLocalState} />
            </Box>
          ) : (
            <Box sx={{ pt: 3 }}>
              <Grid item lg={12} md={12} xs={12}>
                <Card mx={2}>
                  <CardHeader subheader="Create new client" title="Client" />
                  <Divider />
                  <CardContent>
                    <Formik
                      initialValues={{
                        name: '',
                        contactName: '',
                        email: '',
                        phone: ''
                      }}
                      validationSchema={Yup.object().shape({
                        name: Yup.string()
                          .min(3)
                          .max(255)
                          .required('Client name is required'),
                        contactName: Yup.string()
                          .min(3)
                          .max(255)
                          .required('Contact name is required'),
                        email: Yup.string()
                          .email('Must be a valid email')
                          .max(255)
                          .required('Email is required'),
                        phone: Yup.string()
                          .min(10, 'Phone number is too short')
                          .max(10, 'Phone number is too long')
                          .required('Phone Number is required')
                      })}
                      onSubmit={(values) => {
                        dispatch(addClient(values));
                        dispatch(getClients({ email: user.email }));
                        setIsAddClient(false);
                        toast.success('Client record got saved successfully');
                      }}
                    >
                      {({
                        errors,
                        handleBlur,
                        handleSubmit,
                        handleChange,
                        touched,
                        values
                      }) => (
                        <form onSubmit={handleSubmit}>
                          <Grid container spacing={3}>
                            <Grid item md={6} xs={12}>
                              <TextField
                                error={Boolean(touched.name && errors.name)}
                                fullWidth
                                helperText={touched.name && errors.name}
                                label="Client Name"
                                margin="normal"
                                name="name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="text"
                                value={values.name}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <TextField
                                error={Boolean(
                                  touched.contactName && errors.contactName
                                )}
                                fullWidth
                                helperText={
                                  touched.contactName && errors.contactName
                                }
                                label="Contact Name"
                                margin="normal"
                                name="contactName"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="text"
                                value={values.contactName}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <TextField
                                error={Boolean(touched.email && errors.email)}
                                fullWidth
                                helperText={touched.email && errors.email}
                                label="Email Address"
                                margin="normal"
                                name="email"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="email"
                                value={values.email}
                                variant="outlined"
                              />
                            </Grid>
                            <Grid item md={6} xs={12}>
                              <TextField
                                error={Boolean(touched.phone && errors.phone)}
                                fullWidth
                                helperText={touched.phone && errors.phone}
                                label="Phone Number"
                                margin="normal"
                                name="phone"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                type="tel"
                                value={values.phone}
                                variant="outlined"
                              />
                            </Grid>
                          </Grid>

                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              p: 2
                            }}
                          >
                            <Button
                              onClick={() => setIsAddClient(false)}
                              color="secondary"
                              size="large"
                              type="submit"
                              variant="contained"
                            >
                              CANCEL
                            </Button>
                            <Button
                              color="primary"
                              size="large"
                              type="submit"
                              variant="contained"
                            >
                              SAVE
                            </Button>
                          </Box>
                          <Typography
                            color="red"
                            variant="body1"
                            align="center"
                          >
                            {errorState &&
                              errorState.info &&
                              errorState.info.msg}
                          </Typography>
                        </form>
                      )}
                    </Formik>
                  </CardContent>
                </Card>
              </Grid>
            </Box>
          )}
        </Container>
      </Box>
    </>
  );
};

export default Clients;
