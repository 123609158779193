/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable quotes */
/* eslint-disable react/jsx-curly-brace-presence */
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Grid,
    Typography,
    Box,
    Button
  } from '@material-ui/core';
  
  const Reflection_Instructions = (props) => {
    const assessmentType = props.assessmentType;
    const participantName = props.participantname;
    const assessorName = props.assessorname;
    const relation = props.relation;
    return (
      <>
        <Grid container spacing={6} wrap="wrap">
          <Grid item lg={6} md={6} sm={6} sx={12} xs={12}>
            <Typography
              color="textPrimary"
              gutterBottom
              variant="body1"
              style={styles.nameStyle}
            >
              {`Hi ${participantName},`}
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={6} sx={12} xs={12}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: 10
              }}
            >
              <img
                alt="Logo"
                width={70}
                height={55}
                src="/static/logo.png"
                {...props}
              />
            </Box>
          </Grid>
        </Grid>
        <Card style={styles.cardStyle}>
          <CardHeader
            color="primary"
            title="Instructions"
            style={styles.cardHeaderStyle}
          />
          <Divider />
          <CardContent style={styles.cardContentStyle}>
            <Grid container spacing={6} wrap="wrap">
              <Grid item md={12} sm={12} sx={12} xs={12}>
                <Typography color="textPrimary" variant="body2">
                  {`This is a space for you to explore yourself freely, and it will remain completely confidential. And, here are some suggestions to enhance the effectiveness of your self-reflection process:`}
                </Typography>
                <h4 style={styles.nameStyle}>{`Preparation`}</h4>
                <ul style={styles.listWrapInsStyle}>
                    <li style={styles.listInsStyle}>
                        <Typography color="textPrimary" variant="body2">
                        {`Schedule dedicated time for self-reflection`}
                        </Typography>
                    </li> 
                    <li style={styles.listInsStyle}>
                        <Typography color="textPrimary" variant="body2">
                        {`Choose a quiet, comfortable space`}
                        </Typography>
                    </li>  
                    <li style={styles.listInsStyle}>
                        <Typography color="textPrimary" variant="body2">
                        {`Gather necessary materials (write it down in a paper and then enter it here)`}
                        </Typography>
                    </li>
                    <li style={styles.listInsStyle}>
                        <Typography color="textPrimary" variant="body2">
                        {`Review relevant notes, experiences, or assessments`}
                        </Typography>
                    </li>   
                    <li style={styles.listInsStyle}>
                        <Typography color="textPrimary" variant="body2">
                        {`The "Prompt Questions" are cues to help you reflect better`}
                        </Typography>
                    </li>   
                </ul>
                <h4 style={styles.nameStyle}>{`Reflection Process`}</h4>
                <ul style={styles.listWrapInsStyle}>
                    <li style={styles.listInsStyle}>
                        <Typography color="textPrimary" variant="body2">
                        {`Use “I” statements`}
                        </Typography>
                    </li> 
                    <li style={styles.listInsStyle}>
                        <Typography color="textPrimary" variant="body2">
                        {`Write freely, without editing or judgment`}
                        </Typography>
                    </li>  
                    <li style={styles.listInsStyle}>
                        <Typography color="textPrimary" variant="body2">
                        {`Explore thoughts, feelings, and experiences`}
                        </Typography>
                    </li>
                    <li style={styles.listInsStyle}>
                        <Typography color="textPrimary" variant="body2">
                        {`Identify key insights, patterns, or themes`}
                        </Typography>
                    </li>
                    <li style={styles.listInsStyle}>
                        <Typography color="textPrimary" variant="body2">
                        {`Consider multiple perspectives (talking with people you trust can also help)`}
                        </Typography>
                    </li>   
                </ul>

                <h4 style={styles.nameStyle}>{`Tips for Deep Reflection`}</h4>
                <ul style={styles.listWrapInsStyle}>
                    <li style={styles.listInsStyle}>
                        <Typography color="textPrimary" variant="body2">
                        {`Be honest and authentic`}
                        </Typography>
                    </li> 
                    <li style={styles.listInsStyle}>
                        <Typography color="textPrimary" variant="body2">
                        {`Avoid self-criticism`}
                        </Typography>
                    </li>  
                    <li style={styles.listInsStyle}>
                        <Typography color="textPrimary" variant="body2">
                        {`Avoid self-criticism`}
                        </Typography>
                    </li>
                    <li style={styles.listInsStyle}>
                        <Typography color="textPrimary" variant="body2">
                        {`Seek connections between experiences`}
                        </Typography>
                    </li>
                    <li style={styles.listInsStyle}>
                        <Typography color="textPrimary" variant="body2">
                        {`Celebrate growth and progress`}
                        </Typography>
                    </li>   
                </ul>

              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}
          >
            <Button
              color="primary"
              variant="contained"
              onClick={props.handleStartClick}
            >
              Start
            </Button>
          </Box>
        </Card>
      </>
    );
  };
  
  const styles = {
    nameStyle: {
      margin: 10
    },
    cardStyle: {
      boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.35)',
      borderRadius: 10
    },
    cardContentStyle: {
      marginLeft: 10
    },
    listWrapInsStyle: {
      marginLeft: 20
      
    },
    listInsStyle: {
      paddingTop: 5    
    },
    cardHeaderStyle: {
      color: '#f77445',
      backgroundColor: '#f4f4f4'
    }
  };
  
  export default Reflection_Instructions;
  