import { ErrorActionTypes } from './error.types';

export const receiveErrors = (payload) => ({
  type: ErrorActionTypes.RECEIVE_ERRORS,
  payload
});

export const clearErrors = () => ({
  type: ErrorActionTypes.CLEAR_ERRORS
});
