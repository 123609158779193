/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import {
  Avatar,
  Link,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import html2canvas from 'html2canvas';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useState, useEffect } from 'react';

const QualitativeSummary = (props) => {
  const qualitativeSummaryInfoState = props.qualitativeSummary;
  const [selectedQuestion, setSelectedQuestion] = useState('ques_1');
  const [currentQuestion, setCurrrentQuestion] = useState([]);
  const [adminView, setAdminView] = useState(false);

  useEffect(() => {
    if (selectedQuestion == 'ques_1') {
      let firstElement =
        qualitativeSummaryInfoState[
          Object.keys(qualitativeSummaryInfoState)[0]
        ];
      firstElement.sort((a, b) =>
        a.relation > b.relation ? 1 : b.relation > a.relation ? -1 : 0
      );
      setCurrrentQuestion(firstElement);
    } else if (selectedQuestion == 'ques_2') {
      let secondElement =
        qualitativeSummaryInfoState[
          Object.keys(qualitativeSummaryInfoState)[1]
        ];
      secondElement.sort((a, b) =>
        a.relation > b.relation ? 1 : b.relation > a.relation ? -1 : 0
      );
      setCurrrentQuestion(secondElement);
    } else if (selectedQuestion == 'ques_3') {
      let thirdElement =
        qualitativeSummaryInfoState[
          Object.keys(qualitativeSummaryInfoState)[2]
        ];
      thirdElement.sort((a, b) =>
        a.relation > b.relation ? 1 : b.relation > a.relation ? -1 : 0
      );
      setCurrrentQuestion(thirdElement);
    }
  }, [selectedQuestion]);

  return (
    <Grid container xs={12} sm={12} justifyContent="center">
      <Grid item>
        <Grid container xs={12} sm={12} style={{ marginBottom: 20 }}>
          <Grid item xs={10} sm={10}>
            <Typography gutterBottom variant="h6">
              <Link
                href="#"
                onClick={() => {
                  setAdminView(false);
                  setSelectedQuestion('ques_1');
                }}
              >
                {`Question 1 | `}
              </Link>
              <Link
                href="#"
                onClick={() => {
                  setAdminView(false);
                  setSelectedQuestion('ques_2');
                }}
              >
                {`Question 2 | `}
              </Link>
              <Link
                href="#"
                onClick={() => {
                  setAdminView(false);
                  setSelectedQuestion('ques_3');
                }}
              >
                {`Question 3 `}
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2}>
            <Typography gutterBottom variant="h6">
              <Link
                href="#"
                onClick={() => {
                  setAdminView(true);
                }}
              >
                {`Admin View | `}
              </Link>
              <Link
                href="#"
                onClick={() => {
                  html2canvas(document.getElementById(selectedQuestion)).then(
                    function (canvas) {
                      canvas.toBlob(function (blob) {
                        navigator.clipboard
                          .write([
                            new ClipboardItem(
                              Object.defineProperty({}, blob.type, {
                                value: blob,
                                enumerable: true
                              })
                            )
                          ])
                          .then(function () {
                            console.log('Copied to clipboard');
                            // domNode.classList.remove('on');
                          });
                      });
                    }
                  );
                }}
              >
                {`Copy`}
              </Link>
            </Typography>
          </Grid>
        </Grid>
        {adminView && (
          <Card {...props}>
            <CardContent>
              <Grid
                container
                alignItems="center"
                xs={12}
                sm={12}
                justifyContent="center"
              >
                {selectedQuestion == 'ques_1' && adminView && (
                  <Grid item>
                    {currentQuestion.length > 0 && (
                      <Typography variant="h5" style={{ margin: 20 }}>
                        {currentQuestion[0].question_desc}
                      </Typography>
                    )}
                    <Table aria-label="simple table" style={{ width: '800px' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Relation</TableCell>
                          <TableCell>Response</TableCell>
                          <TableCell>Assessor Name</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentQuestion &&
                          currentQuestion.length > 0 &&
                          currentQuestion.map((resp) => (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                {resp.relation}
                              </TableCell>
                              <TableCell>{resp.answer}</TableCell>
                              <TableCell>{resp.assessor_first_name}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </Grid>
                )}
                {selectedQuestion == 'ques_2' && adminView && (
                  <Grid item>
                    {currentQuestion.length > 0 && (
                      <Typography variant="h5" style={{ margin: 20 }}>
                        {currentQuestion[1].question_desc}
                      </Typography>
                    )}
                    <Table aria-label="simple table" style={{ width: '800px' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Relation</TableCell>
                          <TableCell>Response</TableCell>
                          <TableCell>Assessor Name</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentQuestion &&
                          currentQuestion.length > 0 &&
                          currentQuestion.map((resp) => (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                {resp.relation}
                              </TableCell>
                              <TableCell>{resp.answer}</TableCell>
                              <TableCell>{resp.assessor_first_name}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </Grid>
                )}
                {selectedQuestion == 'ques_3' && adminView && (
                  <Grid item>
                    {currentQuestion.length > 0 && (
                      <Typography variant="h5" style={{ margin: 20 }}>
                        {currentQuestion[2].question_desc}
                      </Typography>
                    )}
                    <Table aria-label="simple table" style={{ width: '800px' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Relation</TableCell>
                          <TableCell>Response</TableCell>
                          <TableCell>Assessor Name</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentQuestion &&
                          currentQuestion.length > 0 &&
                          currentQuestion.map((resp) => (
                            <TableRow>
                              <TableCell component="th" scope="row">
                                {resp.relation}
                              </TableCell>
                              <TableCell>{resp.answer}</TableCell>
                              <TableCell>{resp.assessor_first_name}</TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        )}
        {!adminView && (
          <Card {...props}>
            <CardContent>
              <Grid
                container
                alignItems="center"
                xs={12}
                sm={12}
                justifyContent="center"
              >
                {selectedQuestion == 'ques_1' && !adminView && (
                  <Grid item id="ques_1">
                    {currentQuestion.length > 0 && (
                      <Typography variant="h5" style={{ marginBottom: 20 }}>
                        {`1. ${currentQuestion[0].question_desc}`}
                      </Typography>
                    )}
                    <Table
                      aria-label="simple table"
                      style={{
                        width: '800px',
                        borderColor: '#D8D8D8',
                        borderWidth: 1,
                        borderStyle: 'ridge'
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Relation</TableCell>
                          <TableCell>Response</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentQuestion &&
                          currentQuestion.length > 0 &&
                          currentQuestion.map((resp) => (
                            <TableRow>
                              {resp.relation == 'Self' && (
                                <>
                                  <TableCell component="th" scope="row">
                                    {resp.relation}
                                  </TableCell>
                                  <TableCell>{resp.answer}</TableCell>
                                </>
                              )}
                            </TableRow>
                          ))}
                        <>
                          {currentQuestion &&
                            currentQuestion.length > 0 &&
                            currentQuestion.map((resp, index) => (
                              <>
                                {resp.relation != 'Self' && (
                                  <TableRow>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{ borderBottom: 0 }}
                                    >
                                      {index == 0 && <>Others</>}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        borderBottom: 0,
                                        marginBottom: 0,
                                        paddingBottom: 0
                                      }}
                                    >
                                      {resp.answer}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </>
                            ))}
                        </>
                      </TableBody>
                    </Table>
                  </Grid>
                )}
                {selectedQuestion == 'ques_2' && !adminView && (
                  <Grid item id="ques_2">
                    {currentQuestion.length > 0 && (
                      <Typography variant="h5" style={{ marginBottom: 20 }}>
                        {`2. ${currentQuestion[1].question_desc}`}
                      </Typography>
                    )}
                    <Table
                      aria-label="simple table"
                      style={{
                        width: '800px',
                        borderColor: '#D8D8D8',
                        borderWidth: 1,
                        borderStyle: 'ridge'
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Relation</TableCell>
                          <TableCell>Response</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentQuestion &&
                          currentQuestion.length > 0 &&
                          currentQuestion.map((resp) => (
                            <TableRow>
                              {resp.relation == 'Self' && (
                                <>
                                  <TableCell component="th" scope="row">
                                    {resp.relation}
                                  </TableCell>
                                  <TableCell>{resp.answer}</TableCell>
                                </>
                              )}
                            </TableRow>
                          ))}
                        <>
                          {currentQuestion &&
                            currentQuestion.length > 0 &&
                            currentQuestion.map((resp, index) => (
                              <>
                                {resp.relation != 'Self' && (
                                  <TableRow>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{ borderBottom: 0 }}
                                    >
                                      {index == 0 && <>Others</>}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        borderBottom: 0,
                                        marginBottom: 0,
                                        paddingBottom: 0
                                      }}
                                    >
                                      {resp.answer}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </>
                            ))}
                        </>
                      </TableBody>
                    </Table>
                  </Grid>
                )}
                {selectedQuestion == 'ques_3' && !adminView && (
                  <Grid item gridGap="10" id="ques_3">
                    {currentQuestion.length > 0 && (
                      <Typography variant="h5" style={{ marginBottom: 20 }}>
                        {`3. ${currentQuestion[2].question_desc}`}
                      </Typography>
                    )}
                    <Table
                      aria-label="simple table"
                      style={{
                        width: '800px',
                        borderColor: '#D8D8D8',
                        borderWidth: 1,
                        borderStyle: 'ridge'
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Relation</TableCell>
                          <TableCell>Response</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {currentQuestion &&
                          currentQuestion.length > 0 &&
                          currentQuestion.map((resp) => (
                            <TableRow>
                              {resp.relation == 'Self' && (
                                <>
                                  <TableCell component="th" scope="row">
                                    {resp.relation}
                                  </TableCell>
                                  <TableCell>{resp.answer}</TableCell>
                                </>
                              )}
                            </TableRow>
                          ))}
                        <>
                          {currentQuestion &&
                            currentQuestion.length > 0 &&
                            currentQuestion.map((resp, index) => (
                              <>
                                {resp.relation != 'Self' && (
                                  <TableRow>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{ borderBottom: 0 }}
                                    >
                                      {index == 0 && <>Others</>}
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        borderBottom: 0,
                                        marginBottom: 0,
                                        paddingBottom: 0
                                      }}
                                    >
                                      {resp.answer}
                                    </TableCell>
                                  </TableRow>
                                )}
                              </>
                            ))}
                        </>
                      </TableBody>
                    </Table>
                  </Grid>
                )}
              </Grid>
            </CardContent>
          </Card>
        )}
      </Grid>
    </Grid>
  );
};

export default QualitativeSummary;
