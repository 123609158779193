import fetch from 'unfetch';
import { merge } from 'lodash';
import { getAuthCookie } from './cookie';

// const host_local = 'http://localhost:9201/zygote/api';
const host_prod =
  'https://core.zygoteindia.com/zygote/api';

export const SERVER_METHODS = { GET: 'GET', POST: 'POST' };
export const requestServer = async (method, path, payload) => {
  let options = { headers: { zid: getAuthCookie() } };
  if (method === SERVER_METHODS.POST) {
    options = merge(options, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(payload),
      mode: 'no-cors'
    });
  } else {
    options = merge(options, {
      method: SERVER_METHODS.GET
    });
  }
  const res = await fetch(host_prod + path, options);
  return res;
};
