import { ErrorActionTypes } from './error.types';

const errorReducer = (state = {}, { payload, type }) => {
  switch (type) {
    case ErrorActionTypes.RECEIVE_ERRORS: {
      return { ...state, ...payload };
    }
    case ErrorActionTypes.CLEAR_ERRORS:
      return '';
    default:
      return state;
  }
};

export default errorReducer;
