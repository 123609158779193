import UserActionTypes from './user.types';

const INITIAL_STATE = {
  currentUser: null,
  userType: null,
  isSessionValid: false,
  loading: false
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.SET_SESSION_VALIDATED:
      return { ...state, ...action.payload };
    case UserActionTypes.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload
      };
    case UserActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case UserActionTypes.SET_USER_TYPE:
      return {
        ...state,
        userType: action.payload
      };
    case UserActionTypes.LOGOUT_CURRENT_USER:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export default userReducer;
