import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import GroupsIcon from '@material-ui/icons/Groups';

const Logo = (props) => {
  const type = useSelector((state) => state.assessment.type);
  const userType = useSelector((state) => state.user.userType);
  return (
    <>
      {(type === 'NESW' || userType === 'Admin') && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <img
            alt="Logo"
            width={164}
            height={60}
            src="/static/z_360.png"
            {...props}
            style={{ marginLeft: -10 }}
          />
        </div>
      )}
      {type === 'PA' && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <img
            alt="Logo"
            width={164}
            height={60}
            src="/static/z_PA.png"
            {...props}
            style={{ marginLeft: -10 }}
          />
        </div>
      )}
      {type === 'TEAM_CULTURE' && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
          }}
        >
          <GroupsIcon style={styles.iconStyle} />
          <Typography
            style={styles.nameStyle}
            color="#ffffff"
            gutterBottom
            variant="body1"
            fontWeight="bold"
          >
            Team Culture Assessment
          </Typography>
          {/* <img
            alt="Logo"
            width={164}
            height={60}
            src="/static/z_TC.png"
            {...props}
            style={{ marginLeft: -10 }}
          /> */}
        </div>
      )}
    </>
  );
};

const styles = {
  nameStyle: {
    margin: 10,
    paddingTop: 4
  },
  iconStyle: {
    color: '#ffffff',
    fontSize: '40px'
  }
};
export default Logo;
