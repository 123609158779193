import { SERVER_METHODS, requestServer } from '../utils/request';
import ApplicationError from '../utils/applicationError';

export const fetchTotalInfo = async (user) => {
  const path = '/client/fetch_total_info';
  try {
    const res = await requestServer(SERVER_METHODS.POST, path, user);
    if (res.status === 200) {
      return await res.json();
    }
    throw new ApplicationError(res.status, await res.json());
  } catch (error) {
    if (error instanceof ApplicationError) throw error;
    else
      throw JSON.stringify({
        code: 'INT',
        info: {
          msg: 'UNKNOWN ERROR'
        }
      });
  }
};

export const fetchAllClients = async (user) => {
  const path = '/client/fetch_clients';
  try {
    const res = await requestServer(SERVER_METHODS.POST, path, user);
    if (res.status === 200) {
      return await res.json();
    }
    throw new ApplicationError(res.status, await res.json());
  } catch (error) {
    if (error instanceof ApplicationError) throw error;
    else
      throw JSON.stringify({
        code: 'INT',
        info: {
          msg: 'UNKNOWN ERROR'
        }
      });
  }
};

export const fetchParticipants = async (client) => {
  const path = '/user/participants';
  try {
    const res = await requestServer(SERVER_METHODS.POST, path, client);
    if (res.status === 200) {
      return await res.json();
    }
    throw new ApplicationError(res.status, await res.json());
  } catch (error) {
    if (error instanceof ApplicationError) throw error;
    else
      throw JSON.stringify({
        code: 'INT',
        info: {
          msg: 'UNKNOWN ERROR'
        }
      });
  }
};

export const fetchFeedbacks = async (summary) => {
  let path = '';
  if (summary && summary.reportType === 'question_summary') {
    path = '/summary/question';
  } else if (summary && summary.reportType === 'section_summary') {
    path = '/summary/section';
  } else if (summary && summary.reportType === 'personality_assessment') {
    path = '/summary/personal';
  }
  try {
    const res = await requestServer(SERVER_METHODS.POST, path, summary);
    if (res.status === 200) {
      return await res.json();
    }
    throw new ApplicationError(res.status, await res.json());
  } catch (error) {
    if (error instanceof ApplicationError) throw error;
    else
      throw JSON.stringify({
        code: 'INT',
        info: {
          msg: 'UNKNOWN ERROR'
        }
      });
  }
};

export const fetchPersonalAssessmentService = async (paObj) => {
  let path = '/pa_report';
  try {
    const res = await requestServer(SERVER_METHODS.POST, path, paObj);
    if (res.status === 200) {
      return await res.json();
    }
  } catch (error) {
    if (error instanceof ApplicationError) throw error;
    else
      throw JSON.stringify({
        code: 'INT',
        info: {
          msg: 'UNKNOWN ERROR'
        }
      });
  }
};

export const fetchTeamProfileService = async (teamObj) => {
  let path = '/team_profile';
  try {
    const res = await requestServer(SERVER_METHODS.POST, path, teamObj);
    if (res.status === 200) {
      return await res.json();
    }
  } catch (err) {
    if (error instanceof ApplicationError) throw error;
    else
      throw JSON.stringify({
        code: 'INT',
        info: {
          msg: 'UNKNOWN ERROR'
        }
      });
  }
};

export const fetchSectionSummaryService = async (sectionObj) => {
  let path = '/section_summary_report';
  try {
    const res = await requestServer(SERVER_METHODS.POST, path, sectionObj);
    if (res.status === 200) {
      return await res.json();
    }
  } catch (err) {
    if (error instanceof ApplicationError) throw error;
    else
      throw JSON.stringify({
        code: 'INT',
        info: {
          msg: 'UNKNOWN ERROR'
        }
      });
  }
};

export const fetchQuestionSummaryService = async (quesObj) => {
  let path = '/question_summary_report';
  try {
    const res = await requestServer(SERVER_METHODS.POST, path, quesObj);
    if (res.status == 200) {
      return await res.json();
    }
  } catch (err) {
    if (error instanceof ApplicationError) throw error;
    else
      throw JSON.stringify({
        code: 'INT',
        info: {
          msg: 'UNKNOWN ERROR'
        }
      });
  }
};

export const fetchQualitativeSummaryService = async (qualObj) => {
  let path = '/qualitative_report';
  try {
    const res = await requestServer(SERVER_METHODS.POST, path, qualObj);
    if (res.status == 200) {
      return await res.json();
    }
  } catch (err) {
    if (error instanceof ApplicationError) throw error;
    else
      throw JSON.stringify({
        code: 'INT',
        info: {
          msg: 'UNKNOWN ERROR'
        }
      });
  }
};
