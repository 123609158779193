import { createMuiTheme, colors } from '@material-ui/core';
import shadows from './shadows';
import typography from './typography';

const theme = createMuiTheme({
  palette: {
    background: {
      default: '#F4F6F8',
      paper: colors.common.white
    },
    primary: {
      contrastText: '#ffffff',
      // main: '#5664d2',
      main: '#f77445'
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#D8D8D8',
    },
    text: {
      primary: '#172b4d',
      secondary: '#6b778c',
      light: '#D8D8D8'
    }
  },
  shadows,
  typography
});

export default theme;
