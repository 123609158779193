/* eslint-disable indent */
/* eslint-disable operator-linebreak */
/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import customers from 'src/__mocks__/customers';
import { setLoading } from 'src/redux/user/user.actions';
import {
  fetchFeedbacks,
  fetchParticipants,
  fetchPersonalAssessment,
  fetchSectionSummary,
  fetchQuestionSummary,
  fetchTeamProfile,
  resetSummaryData,
  fetchQualitativeSummary
} from 'src/redux/dashboard/dashboard.action';
import CustomerListResults from '../customer/CustomerListResults';
import SectionSummary from './SectionSummary';
import QuestionSummary from './QuestionSummary';
import PersonalSummary from './PersonalSummary';
import QualitativeSummary from './QualitativeSummary';

const Reports = (props) => {
  const dispatch = useDispatch();
  const clients = props.clients;
  const reportTypes = props.reportTypes;
  const user = props.user;
  const participantState = useSelector((state) => state.dashboard.participants);
  const feedbackList = useSelector((state) => state.dashboard.feedbacks);
  const personalReport = useSelector(
    (state) => state.dashboard.personalAssessment
  );
  const teamProfileReport = useSelector((state) => state.dashboard.teamProfile);
  const sectionSummaryReport = useSelector(
    (state) => state.dashboard.sectionSummary
  );
  const questionSummaryReport = useSelector(
    (state) => state.dashboard.questionSummary
  );
  const qualitativeSummaryReport = useSelector(
    (state) => state.dashboard.qualitativeSummary
  );
  const [selectedValues, setSelectedValues] = useState({
    client: null,
    participant: null,
    reportType: null
  });
  const [disableValues, setDisableValues] = useState({
    participant: true,
    reportTypes: true,
    goButton: true
  });

  const [showCard, setShowCard] = useState(true);

  const handleClientChange = async (e) => {
    try {
      dispatch(setLoading(true));
      dispatch(resetSummaryData());
      setSelectedValues({ ...selectedValues, client: e.target.value });
      setDisableValues({ participants: false });
      dispatch(fetchParticipants({ clientId: e.target.value }));
      dispatch(setLoading(false));
    } catch (err) {
      console.log(err);
    }
  };

  const handleParticipantChange = async (e) => {
    try {
      dispatch(setLoading(true));
      dispatch(resetSummaryData());
      setSelectedValues({ ...selectedValues, participant: e.target.value });
      setDisableValues({ goButton: false });
      dispatch(setLoading(false));
    } catch (err) {
      console.log(err);
    }
  };

  const handleReportTypeChange = async (e) => {
    try {
      dispatch(setLoading(true));
      setSelectedValues({ ...selectedValues, reportType: e.target.value });
      setDisableValues({ reportTypes: false });
      dispatch(setLoading(false));
    } catch (err) {
      console.log(err);
    }
  };

  const handleShowCard = () => {
    try {
      setShowCard(true);
    } catch (err) {
      console.log(err);
    }
  };

  const fetchReport = async () => {
    try {
      dispatch(setLoading(true));
      // setShowCard(false);
      if (selectedValues.reportType == 'personality_assessment') {
        dispatch(
          fetchPersonalAssessment({
            clientId: selectedValues.client,
            assignmentType: 'PA',
            participantId: selectedValues.participant
          })
        );
        dispatch(
          fetchTeamProfile({
            clientId: selectedValues.client,
            assignmentType: 'PA'
          })
        );
      } else if (selectedValues.reportType == 'section_summary') {
        dispatch(
          fetchSectionSummary({
            clientId: selectedValues.client,
            assignmentType: 'NESW',
            reportType: 'section_summary',
            participantId: selectedValues.participant
          })
        );
      } else if (selectedValues.reportType == 'question_summary') {
        dispatch(
          fetchQuestionSummary({
            clientId: selectedValues.client,
            assignmentType: 'NESW',
            reportType: 'question_summary',
            participantId: selectedValues.participant
          })
        );
      } else if (selectedValues.reportType == 'qualitative_summary') {
        dispatch(
          fetchQualitativeSummary({
            clientId: selectedValues.client,
            assignmentType: 'NESW',
            reportType: 'qualitative_summary',
            participantId: selectedValues.participant
          })
        );
      }
      dispatch(setLoading(false));
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (participantState.length > 0) {
      setSelectedValues({
        ...selectedValues,
        participant: participantState[0].id
      });
    }
  }, [participantState]);

  useEffect(() => {
    setDisableValues({ participants: false });
    dispatch(fetchParticipants({ clientId: selectedValues.client }));
  }, [selectedValues.client]);

  useEffect(() => {
    // if (props.clients.length > 0) {
    //   console.log('coming here$$$$$ props length > 0');
    //   setSelectedValues({ ...selectedValues, client: clients[0].id });
    // }
    // if (reportTypes.length > 0) {
    //   console.log(
    //     'coming here$$$$$ props length > 0 --2',
    //     reportTypes[0].value
    //   );
    //   setSelectedValues({
    //     ...selectedValues,
    //     reportType: reportTypes[0]
    //   });
    // }
  }, [clients, reportTypes]);

  return (
    <Card {...props}>
      {showCard && (
        <>
          <CardHeader
            action={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <Button
                endIcon={<ArrowDropDownIcon />}
                size="small"
                variant="text"
              >
                DOWNLOAD AS EXCEL
              </Button>
            }
            title="Summary"
          />
          <Divider />
        </>
      )}
      <CardContent>
        {showCard && (
          <Box
            sx={{
              position: 'relative'
            }}
          >
            <Grid container spacing={3}>
              <Grid item lg={3} sm={3} xl={3} xs={12}>
                <TextField
                  fullWidth
                  label="Select Client"
                  name="clients"
                  onChange={handleClientChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={
                    selectedValues.client != null ? selectedValues.client : '--'
                  }
                  variant="outlined"
                >
                  <option key="None" value="None">
                    -- None --
                  </option>
                  {clients.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item lg={3} sm={3} xl={3} xs={12}>
                <TextField
                  fullWidth
                  label="Select Participant"
                  name="participant"
                  disabled={disableValues.participant}
                  onChange={handleParticipantChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={
                    selectedValues.participant != null
                      ? selectedValues.participant
                      : '--'
                  }
                  variant="outlined"
                >
                  <option key="None" value="None">
                    -- None --
                  </option>
                  {participantState.map((option) => (
                    <option key={option.id} value={option.id}>
                      {`${option.first_name} ${option.last_name}`}
                    </option>
                  ))}
                </TextField>
              </Grid>
              <Grid item lg={3} sm={3} xl={3} xs={12}>
                <TextField
                  fullWidth
                  label="Select Report Type"
                  name="reportType"
                  disabled={disableValues.reportTypes}
                  onChange={handleReportTypeChange}
                  required
                  select
                  SelectProps={{ native: true }}
                  value={
                    selectedValues.reportType != null
                      ? selectedValues.reportType
                      : '--'
                  }
                  variant="outlined"
                >
                  <option key="None" value="None">
                    -- None --
                  </option>
                  {reportTypes.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </Grid>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignSelf: 'center',
                  p: 2,
                  m: 2
                }}
              >
                <Button
                  color="primary"
                  variant="contained"
                  disabled={disableValues.goButton}
                  onClick={fetchReport}
                >
                  GO
                </Button>
              </Box>
            </Grid>
          </Box>
        )}
        {selectedValues.reportType != null &&
          selectedValues.reportType === 'section_summary' &&
          sectionSummaryReport.length > 0 && (
            <SectionSummary
              sectionSummary={sectionSummaryReport}
              handleShowCard={handleShowCard}
            />
          )}
        {selectedValues.reportType != null &&
          selectedValues.reportType === 'question_summary' &&
          questionSummaryReport.length > 0 && (
            <QuestionSummary
              questionSummary={questionSummaryReport}
              handleShowCard={handleShowCard}
            />
          )}
        {selectedValues.reportType != null &&
          selectedValues.reportType === 'qualitative_summary' &&
          qualitativeSummaryReport != null && (
            <QualitativeSummary
              qualitativeSummary={qualitativeSummaryReport}
              handleShowCard={handleShowCard}
            />
          )}
        {selectedValues.reportType != null &&
          selectedValues.reportType === 'personality_assessment' &&
          personalReport.length > 0 &&
          teamProfileReport != null && (
            <PersonalSummary
              personalReport={personalReport[0]}
              teamProfile={teamProfileReport}
            />
          )}
      </CardContent>
      <Divider />
    </Card>
  );
};

export default Reports;
