import { SERVER_METHODS, requestServer } from '../utils/request';
import ApplicationError from '../utils/applicationError';

export const fetchClients = async (userInfo) => {
  const path = `/client/fetch_clients`;
  try {
    const res = await requestServer(SERVER_METHODS.POST, path, userInfo);
    if (res.status === 200) {
      return await res.json();
    }
    throw new ApplicationError(res.status, await res.json());
  } catch (error) {
    if (error instanceof ApplicationError) throw error;
    else
      throw JSON.stringify({
        code: 'INT',
        info: {
          msg: 'UNKNOWN ERROR'
        }
      });
  }
};

export const addClient = async (clientInfo) => {
  const path = `/client/add_client`;
  try {
    const res = await requestServer(SERVER_METHODS.POST, path, clientInfo);
    if (res.status === 200) {
      return await res.json();
    }
    throw new ApplicationError(res.status, await res.json());
  } catch (err) {
    if (error instanceof ApplicationError) throw error;
    else
      throw JSON.stringify({
        code: 'INT',
        info: {
          msg: 'UNKNOWN ERROR'
        }
      });
  }
};
