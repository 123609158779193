import { pick } from 'lodash';
import { SERVER_METHODS, requestServer } from '../utils/request';
import ApplicationError from '../utils/applicationError';

export const login = async (user) => {
  const path = '/user/login';
  try {
    const res = await requestServer(
      SERVER_METHODS.POST,
      path,
      pick(user, ['email', 'password'])
    );
    if (res.status === 200) {
      return await res.json();
    }
    throw new ApplicationError(res.status, await res.json());
  } catch (error) {
    if (error instanceof ApplicationError) throw error;
    else
      throw JSON.stringify({
        code: 'INT',
        info: {
          msg: 'UNKNOWN ERROR'
        }
      });
  }
};

export const getUserInfo = async () => {
  const path = '/user/my_info';
  try {
    const res = await requestServer(SERVER_METHODS.GET, path);
    if (res.status === 200) {
      return await res.json();
    }
    throw new ApplicationError(res.status, await res.json());
  } catch (error) {
    if (error instanceof ApplicationError) throw error;
    else
      throw JSON.stringify({
        code: 'INT',
        info: {
          msg: 'UNKNOWN ERROR'
        }
      });
  }
};

export const changePassword = async (userObj) => {
  const path = '/user/reset';
  try {
    const res = await requestServer(SERVER_METHODS.POST, path, {
      oldPassword: userObj.old_password,
      newPassword: userObj.new_password
    });
    if (res.status === 200) {
      return await res.json();
    }
    throw new ApplicationError(res.status, await res.json());
  } catch (error) {
    if (error instanceof ApplicationError) throw error;
    else
      throw JSON.stringify({
        code: 'INT',
        info: {
          msg: 'UNKNOWN ERROR'
        }
      });
  }
};

export const forgotPassword = async (email) => {
  const path = '/user/forgot_password';
  try {
    const res = await requestServer(SERVER_METHODS.POST, path, { email });
    if (res.status === 200) {
      return await res.json();
    }
    throw new ApplicationError(res.status, await res.json());
  } catch (error) {
    if (error instanceof ApplicationError) throw error;
    else
      throw JSON.stringify({
        code: 'INT',
        info: {
          msg: 'UNKNOWN ERROR'
        }
      });
  }
};

export const getUser = async (searchKey) => {
  const path = '/user/find_users';
  try {
    const res = await requestServer(SERVER_METHODS.POST, path, { searchKey });
    if (res.status === 200) {
      return await res.json();
    }
    throw new ApplicationError(res.status, await res.json());
  } catch (error) {
    if (error instanceof ApplicationError) throw error;
    else
      throw JSON.stringify({
        code: 'INT',
        info: {
          msg: 'UNKNOWN ERROR'
        }
      });
  }
};

export const getClientUsers = async (clientInfo) => {
  const path = '/user/all_users';
  try {
    const res = await requestServer(SERVER_METHODS.POST, path, clientInfo);
    if (res.status === 200) {
      return await res.json();
    }
    throw new ApplicationError(res.status, await res.json());
  } catch (error) {
    if (error instanceof ApplicationError) throw error;
    else
      throw JSON.stringify({
        code: 'INT',
        info: {
          msg: 'UNKNOWN ERROR'
        }
      });
  }
};

export const addClientUsers = async (userInfo) => {
  const path = '/user/add_user';
  try {
    const res = await requestServer(SERVER_METHODS.POST, path, userInfo);
    if (res.status === 200) {
      return await res.json();
    }
    throw new ApplicationError(res.status, await res.json());
  } catch (error) {
    if (error instanceof ApplicationError) throw error;
    else
      throw JSON.stringify({
        code: 'INT',
        info: {
          msg: 'UNKNOWN ERROR'
        }
      });
  }
};
