/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Grid } from '@material-ui/core';
import ClientCard from 'src/components/dashboard/ClientCard';
import LatestOrders from 'src/components/dashboard//LatestOrders';
import LatestProducts from 'src/components/dashboard//LatestProducts';
import Sales from 'src/components/dashboard//Sales';
import AssessmentCard from 'src/components/dashboard/AssessmentCard';
import ParticipantCard from 'src/components/dashboard/ParticipantCard';
import FeedbackCard from 'src/components/dashboard/FeedbackCard';
import TrafficByDevice from 'src/components/dashboard//TrafficByDevice';
import Reports from 'src/components/dashboard/Reports';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from 'src/redux/user/user.actions';
import { fetchClients, totalInfo } from 'src/redux/dashboard/dashboard.action';
import Spinner from 'src/components/Spinner';

const Dashboard = () => {
  const errorState = useSelector((state) => state.errors);
  const user = useSelector((state) => state.user.currentUser);
  const showLoading = useSelector((state) => state.user.loading);
  const totalInfoState = useSelector((state) => state.dashboard.totalInfo);
  const clientState = useSelector((state) => state.dashboard.clients);
  const participantState = useSelector(
    (state) => state.dashboard.pariticipants
  );

  const reportTypes = [
    {
      value: 'personality_assessment',
      label: 'Personality Assessment'
    },
    {
      value: 'section_summary',
      label: '360 Competency Summary'
    },
    {
      value: 'question_summary',
      label: '360 Question Summary'
    },
    {
      value: 'qualitative_summary',
      label: '360 Qualitative Summary'
    }
  ];
  const [summaryInfo, setSummaryInfo] = useState({
    clients: 0,
    pariticipants: 0,
    assessments: 0,
    feedbacks: 0
  });
  const dispatch = useDispatch();

  useEffect(() => {
    if (totalInfoState != null) {
      setSummaryInfo({
        clients: totalInfoState.client_count,
        pariticipants: totalInfoState.person_count,
        assessments: totalInfoState.assignment_count,
        feedbacks: totalInfoState.feedback_count
      });
    }
  }, [totalInfoState]);

  useEffect(() => {}, []);

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(totalInfo({ email: user.email }));
    dispatch(fetchClients({ email: user.email }));
    dispatch(setLoading(false));
  }, []);

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Spinner showLoading={showLoading} />
          <Grid container spacing={3}>
            {/* <Grid item lg={3} sm={6} xl={3} xs={12}>
              <ClientCard clientscount={summaryInfo.clients} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <ParticipantCard participantcount={summaryInfo.pariticipants} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <AssessmentCard assessmentcount={summaryInfo.assessments} />
            </Grid>
            <Grid item lg={3} sm={6} xl={3} xs={12}>
              <FeedbackCard feedbackcount={summaryInfo.feedbacks} />
            </Grid> */}
            <Grid item lg={12} md={12} xl={12} xs={12}>
              {clientState.length > 0 && (
                <Reports
                  reportTypes={reportTypes}
                  clients={clientState}
                  user={user}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
