/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import {
  Avatar,
  Link,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import html2canvas from 'html2canvas';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useState, useEffect } from 'react';
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  Bar,
  ResponsiveContainer,
  Cell,
  BarChart,
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  CartesianGrid,
  XAxis,
  YAxis,
  LabelList
} from 'recharts';

const QuestionSummary = (props) => {
  const maxScore = 5;
  const questionSummaryInfoState = props.questionSummary;
  const [questionSummaryInfo, setQuestionSummaryInfo] = useState([]);
  const [selectedChartType, setSelectedChartType] = useState('highs');

  const CustomBarWithTarget = (props) => {
    const { fill, x, y, width, height, amt, t } = props;

    let totalHeight = y + height;
    let targetY = totalHeight - (height / amt) * t;

    return (
      <svg>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          stroke="none"
          fill={fill}
        />
        <line
          x1={50}
          x2={800}
          y1={295}
          y2={295}
          stroke={'#d8d8d8'}
          strokeWidth={0.5}
          strokeDasharray={'10 5'}
        />
      </svg>
    );
  };

  useEffect(() => {
    if (selectedChartType == 'highs') {
      let topHighArr = questionSummaryInfoState.sort((a, b) => {
        return b.total_avg - a.total_avg;
      });
      setQuestionSummaryInfo(topHighArr.slice(0, 10));
    } else if (selectedChartType == 'lows') {
      let topLowArr = questionSummaryInfoState.sort((a, b) => {
        return a.total_avg - b.total_avg;
      });
      setQuestionSummaryInfo(topLowArr.slice(0, 10));
    } else if (selectedChartType == 'blind_spots') {
      let othersHighSelfLowArr = questionSummaryInfoState.sort((a, b) => {
        return b.gap - a.gap;
      });
      let quesSummaryClone = [...questionSummaryInfoState];
      quesSummaryClone.forEach((ques) => {
        let gapReverseVal = ques.self_val - ques.total_avg;
        ques.gapReverse = gapReverseVal;
      });
      let selfHighOthersLowArr = quesSummaryClone.sort((a, b) => {
        return b.gapReverse - a.gapReverse;
      });
      let combinedArr = [];
      combinedArr.push(...othersHighSelfLowArr.slice(0, 5));
      combinedArr.push(...selfHighOthersLowArr.slice(0, 5));
      setQuestionSummaryInfo(combinedArr);
    }
  }, [selectedChartType]);

  const CustomLabel = (text) => {
    return (
      <Typography
        variant="h6"
        align="center"
        style={{ wordWrap: 'break-word' }}
      >
        {text}
      </Typography>
    );
  };
  return (
    <Grid container xs={12} sm={12} justifyContent="center">
      <Grid item>
        <Grid container xs={12} sm={12} style={{ marginBottom: 20 }}>
          <Grid item xs={10} sm={10}>
            <Typography gutterBottom variant="h6">
              <Link
                href="#"
                onClick={() => {
                  setSelectedChartType('highs');
                }}
              >
                {`Highs | `}
              </Link>
              <Link
                href="#"
                onClick={() => {
                  setSelectedChartType('lows');
                }}
              >
                {`Lows | `}
              </Link>
              <Link
                href="#"
                onClick={() => {
                  setSelectedChartType('blind_spots');
                }}
              >
                {`Blind Spots `}
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={2} sm={2}>
            <Typography gutterBottom variant="h6">
              <Link
                href="#"
                onClick={() => {
                  html2canvas(document.getElementById(selectedChartType)).then(
                    function (canvas) {
                      canvas.toBlob(function (blob) {
                        navigator.clipboard
                          .write([
                            new ClipboardItem(
                              Object.defineProperty({}, blob.type, {
                                value: blob,
                                enumerable: true
                              })
                            )
                          ])
                          .then(function () {
                            console.log('Copied to clipboard');
                            // domNode.classList.remove('on');
                          });
                      });
                    }
                  );
                }}
              >
                {`Copy`}
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item>
            {selectedChartType == 'highs' && (
              <Typography gutterBottom variant="h4" style={{ marginTop: 10 }}>
                Highest Score Statements
              </Typography>
            )}
            {selectedChartType == 'lows' && (
              <Typography gutterBottom variant="h4" style={{ marginTop: 10 }}>
                Lowest Score Statements
              </Typography>
            )}
            {selectedChartType == 'blind_spots' && (
              <Typography gutterBottom variant="h4" style={{ marginTop: 10 }}>
                Blind Spots
              </Typography>
            )}
          </Grid>
        </Grid>
        {/* <Card {...props} style={{ marginBottom: 20 }}>
          <CardContent> */}
        <Grid
          container
          alignItems="center"
          xs={12}
          sm={12}
          justifyContent="center"
        >
          {selectedChartType == 'highs' && (
            <div>
              <Grid
                item
                id="highs"
                style={{
                  borderColor: '#D8D8D8',
                  borderWidth: 0,
                  borderStyle: 'ridge',
                  padding: '20px'
                }}
              >
                {/* <Grid container alignItems="center" justifyContent="center">
                  <Grid item>
                    <Typography
                      gutterBottom
                      variant="h4"
                      style={{ marginTop: 10 }}
                    >
                      Highest Score Statements
                    </Typography>
                  </Grid>
                </Grid> */}
                <div>
                  <BarChart
                    barGap={0}
                    barCategoryGap={12}
                    layout="vertical"
                    width={850}
                    height={600}
                    data={questionSummaryInfo}
                    margin={{
                      top: 25,
                      right: 40,
                      left: -35,
                      bottom: 5
                    }}
                  >
                    {/* <CartesianGrid strokeDasharray="4" /> */}
                    <XAxis
                      type="number"
                      domain={[0, 4]}
                      tickCount={9}
                      tick={{
                        fontSize: '14px',
                        fontFamily: 'Arial'
                      }}
                    />
                    <YAxis
                      type="category"
                      dataKey="question_desc"
                      style={{ fontSize: 12 }}
                      width={300}
                      interval={0}
                      tick={{
                        fontSize: '10px',
                        fontFamily: 'Arial'
                      }}
                    />
                    <Tooltip />
                    <Bar
                      barGap={0}
                      name="Others"
                      dataKey="total_avg"
                      fill="#ec7133"
                    >
                      <LabelList
                        dataKey="total_avg"
                        position="right"
                        style={{ fontSize: 12, fontFamily: 'Arial' }}
                      />
                    </Bar>
                    <Bar
                      barGap={0}
                      name="Self"
                      dataKey="self_val"
                      fill="#508ecc"
                    >
                      <LabelList
                        dataKey="self_val"
                        position="right"
                        style={{ fontSize: 12, fontFamily: 'Arial' }}
                      />
                    </Bar>
                    <Legend
                      wrapperStyle={{
                        height: '10px',
                        paddingTop: '20px',
                        paddingLeft: '150px',
                        fontSize: 14,
                        fontFamily: 'Arial'
                      }}
                    />
                  </BarChart>
                </div>
              </Grid>
            </div>
          )}
          {selectedChartType == 'lows' && (
            <Grid
              item
              id="lows"
              style={{
                borderColor: '#D8D8D8',
                borderWidth: 0,
                borderStyle: 'ridge',
                padding: '20px'
              }}
            >
              {/* <Grid container alignItems="center" justifyContent="center">
                <Grid item>
                  <Typography
                    gutterBottom
                    variant="h4"
                    style={{ marginTop: 10 }}
                  >
                    Lowest Score Statements
                  </Typography>
                </Grid>
              </Grid> */}
              <BarChart
                barGap={0}
                barCategoryGap={12}
                layout="vertical"
                width={850}
                height={600}
                data={questionSummaryInfo}
                margin={{
                  top: 25,
                  right: 40,
                  left: -35,
                  bottom: 5
                }}
              >
                {/* <CartesianGrid strokeDasharray="4" /> */}
                <XAxis
                  type="number"
                  domain={[0, 4]}
                  tickCount={9}
                  tick={{
                    fontSize: '14px',
                    fontFamily: 'Arial'
                  }}
                />
                <YAxis
                  type="category"
                  dataKey="question_desc"
                  style={{ fontSize: 12 }}
                  width={300}
                  interval={0}
                  tick={{
                    fontSize: '10px',
                    fontFamily: 'Arial'
                  }}
                />
                <Tooltip />
                <Bar
                  barGap={0}
                  name="Others"
                  dataKey="total_avg"
                  fill="#ec7133"
                >
                  <LabelList
                    dataKey="total_avg"
                    position="right"
                    style={{ fontSize: 12, fontFamily: 'Arial' }}
                  />
                </Bar>
                <Bar barGap={0} name="Self" dataKey="self_val" fill="#508ecc">
                  <LabelList
                    dataKey="self_val"
                    position="right"
                    style={{ fontSize: 12, fontFamily: 'Arial' }}
                  />
                </Bar>
                <Legend
                  wrapperStyle={{
                    height: '10px',
                    paddingTop: '20px',
                    paddingLeft: '150px',
                    fontSize: 14,
                    fontFamily: 'Arial'
                  }}
                />
              </BarChart>
            </Grid>
          )}
          {selectedChartType == 'blind_spots' && (
            <Grid
              item
              id="blind_spots"
              style={{
                borderColor: '#D8D8D8',
                borderWidth: 0,
                borderStyle: 'ridge',
                padding: '20px'
              }}
            >
              <Grid container alignItems="center" justifyContent="center">
                {/* <Grid item>
                  <Typography
                    gutterBottom
                    variant="h4"
                    style={{ marginTop: 10 }}
                  >
                    Blind Spots
                  </Typography>
                </Grid> */}
              </Grid>
              <BarChart
                barGap={0}
                barCategoryGap={12}
                layout="vertical"
                width={850}
                height={600}
                data={questionSummaryInfo}
                margin={{
                  top: 25,
                  right: 40,
                  left: -35,
                  bottom: 5
                }}
              >
                {/* <CartesianGrid strokeDasharray="4" /> */}
                <XAxis
                  type="number"
                  domain={[0, 4]}
                  tickCount={9}
                  tick={{
                    fontSize: '10px',
                    fontFamily: 'Arial'
                  }}
                />
                <YAxis
                  type="category"
                  dataKey="question_desc"
                  style={{ fontSize: 12 }}
                  width={300}
                  interval={0}
                  tick={{
                    fontSize: '12px',
                    fontFamily: 'Arial'
                  }}
                />
                <Tooltip />
                <Bar
                  shape={<CustomBarWithTarget />}
                  barGap={0}
                  name="Others"
                  dataKey="total_avg"
                  fill="#ec7133"
                >
                  <LabelList
                    dataKey="total_avg"
                    position="right"
                    style={{ fontSize: 12, fontFamily: 'Arial' }}
                  />
                </Bar>
                <Bar barGap={0} name="Self" dataKey="self_val" fill="#508ecc">
                  <LabelList
                    dataKey="self_val"
                    position="right"
                    style={{ fontSize: 12, fontFamily: 'Arial' }}
                  />
                </Bar>
                <Legend
                  wrapperStyle={{
                    height: '10px',
                    paddingTop: '20px',
                    paddingLeft: '150px',
                    fontSize: 14,
                    fontFamily: 'Arial'
                  }}
                />
              </BarChart>
            </Grid>
          )}
        </Grid>
        {/* </CardContent>
        </Card> */}
      </Grid>
    </Grid>
  );
};

export default QuestionSummary;
