import { combineReducers } from 'redux';
import userReducer from './user/user.reducer';
import errorReducer from './error/error.reducer';
import dashboardReducer from './dashboard/dashboard.reducer';
import assessmentReducer from './assessment/assessment.reducer';
import clientReducer from './client/client.reducer';
import clientUserReducer from './client-users/clientuser.reducer';
import taskReducer from './task/task.reducer';

export default combineReducers({
  user: userReducer,
  errors: errorReducer,
  dashboard: dashboardReducer,
  assessment: assessmentReducer,
  client: clientReducer,
  clientuser: clientUserReducer,
  task: taskReducer
});
