import * as _ from 'lodash';
import AssessmentActionTypes from './assessment.types';

const INITIAL_STATE = {
  type: '',
  answerList: []
};

const assessmentReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AssessmentActionTypes.SET_TYPE:
      return {
        ...state,
        type: action.payload
      };
    case AssessmentActionTypes.SET_ANSWER: {
      let existingAnswers = _.cloneDeep(state.answerList);
      let extIndex = existingAnswers.findIndex(
        (ans) => ans.questionId == action.payload.questionId
      );
      if (extIndex > -1) {
        existingAnswers[extIndex].answer = action.payload.answer;
      } else {
        existingAnswers.push(action.payload);
      }
      return {
        ...state,
        answerList: existingAnswers
      };
    }
    case AssessmentActionTypes.REMOVE_ANSWER: {
      let cloneAnswersList = [...state.answerList];
      let newResult = _.remove(
        cloneAnswersList,
        (ans) => ans.questionId == action.payload
      );
      return {
        ...state,
        answerList: cloneAnswersList
      };
    }
    default:
      return state;
  }
};

export default assessmentReducer;
