export default {
  SET_TOTAL_INFO: 'SET_TOTAL_INFO',
  SET_CLIENTS: 'SET_CLIENTS',
  SET_PARTICIPANTS: 'SET_PARTICIPANTS',
  SET_FEEDBACK_SUMMARY: 'SET_FEEDBACK_SUMMARY',
  SET_PERSONAL_ASSESSMENT: 'SET_PERSONAL_ASSESSMENT',
  SET_TEAM_PROFILE: 'SET_TEAM_PROFILE',
  SET_SECTION_SUMMARY: 'SET_SECTION_SUMMARY',
  SET_QUESTION_SUMMARY: 'SET_QUESTION_SUMMARY',
  RESET_SUMMARY_DATA: 'RESET_SUMMARY_DATA',
  SET_QUALITATIVE_SUMMARY: 'SET_QUALITATIVE_SUMMARY'
};
