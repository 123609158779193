/* eslint-disable prefer-destructuring */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable quotes */
/* eslint-disable react/jsx-curly-brace-presence */
import {
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Typography,
  Box,
  Button
} from '@material-ui/core';

const TC_Instructions = (props) => {
  const assessmentType = props.assessmentType;
  const participantName = props.participantname;
  const assessorName = props.assessorname;
  const relation = props.relation;
  return (
    <>
      <Grid container spacing={6} wrap="wrap">
        <Grid item lg={6} md={6} sm={6} sx={12} xs={12}>
          {/* <Typography
            style={styles.nameStyle}
            color="textPrimary"
            gutterBottom
            variant="body1"
          >
            {`Assesse Name : ${participantName} - [ ${
              relation == 'IO' ? 'Manager' : relation
            } ]`}
          </Typography> */}
          <Typography
            style={styles.nameStyle}
            color="textPrimary"
            gutterBottom
            variant="body1"
          >
            {`Name : ${participantName}`}
          </Typography>
        </Grid>
        <Grid item lg={6} md={6} sm={6} sx={12} xs={12}>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: 10
            }}
          >
            <img
              alt="Logo"
              width={70}
              height={55}
              src="/static/logo.png"
              {...props}
            />
          </Box>
        </Grid>
      </Grid>
      <Card style={styles.cardStyle}>
        <CardHeader
          color="primary"
          title="Instructions"
          style={styles.cardHeaderStyle}
        />
        <Divider />
        <CardContent style={styles.cardContentStyle}>
          <Grid container spacing={6} wrap="wrap">
            <Grid item md={12} sm={12} sx={12} xs={12}>
              <Typography color="textPrimary" variant="body2">
                Hi Respondent,{' '}
                <p style={{ marginTop: 20 }}>
                  This questionnaire is about your perception of your team's
                  culture
                </p>
              </Typography>
              <ul style={styles.listWrapInsStyle}>
                {/* <li style={styles.listInsStyle}>
                  <Typography color="textPrimary" variant="body2">
                    {`Be honest - Your feedback will help to improve because they
                want you to be constructive and truthful`}
                  </Typography>
                </li> */}
                <li style={styles.listInsStyle}>
                  <Typography color="textPrimary" variant="body2">
                    {`You must answer all the questions`}
                  </Typography>
                </li>
                <li style={styles.listInsStyle}>
                  <Typography color="textPrimary" variant="body2">
                    {`As far as possible try to base your answers on real observations
                of team's behavior. The marking scale is 1-4 where "1" is
                the lowest and "4" is the highest score.`}
                  </Typography>
                </li>
              </ul>
              <Typography
                color="textPrimary"
                variant="body2"
                style={{ marginTop: 20 }}
              >
                {`If you really can’t answer a question you can choose the “Not
                sure” option. But please choose the option only when you don't
                have sufficient critical incidents. You can score by choosing the
                options given.`}
              </Typography>
              <Table style={{ marginTop: 20 }}>
                <TableHead>
                  <TableRow>
                    <TableCell size="small" color="primary">
                      <Typography color="primary" gutterBottom variant="body2">
                        Score
                      </Typography>
                    </TableCell>
                    <TableCell size="small" color="primary">
                      <Typography color="primary" gutterBottom variant="body2">
                        Rating Criteria
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell size="small">1</TableCell>
                    <TableCell size="small">{`Almost never (1-25% of the times)`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small">2</TableCell>
                    <TableCell size="small">{`Not very often (26-50% of the times)`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small">3</TableCell>
                    <TableCell size="small">{`Most of the time (51-75% of the times)`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell size="small">4</TableCell>
                    <TableCell size="small">{`Nearly always (76-100% of the times)`}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            p: 2
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={props.handleStartClick}
          >
            Start
          </Button>
        </Box>
      </Card>
    </>
  );
};

const styles = {
  nameStyle: {
    margin: 10
  },
  cardStyle: {
    boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.35)',
    borderRadius: 10
  },
  cardContentStyle: {
    marginLeft: 10
  },
  listWrapInsStyle: {
    marginLeft: 20
  },
  listInsStyle: {
    paddingTop: 20
  },
  cardHeaderStyle: {
    color: '#f77445',
    backgroundColor: '#f4f4f4'
  }
};

export default TC_Instructions;
