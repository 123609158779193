/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import {
  Link,
  Card,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import {
  PieChart,
  Pie,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Cell,
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  LabelList
} from 'recharts';
import html2canvas from 'html2canvas';

const PersonalSummary = (props) => {
  const boxStyle = {
    borderWidth: 2,
    backgroundColor: 'gray',
    height: 10,
    width: 100,
    marginTop: 2
  };

  const textStyle = {
    fontSize: 11,
    fontWeight: 'bold',
    fontFamily: 'sans-serif',
    marginLeft: 10,
    marginRight: 10,
    color: '#2d8bd3',
    width: 70
  };

  const headingStyle = {
    fontSize: 11,
    textTransform: 'uppercase',
    fontFamily: 'sans-serif',
    color: 'gray',
    width: 70,
    marginRight: '40',
    marginLeft: '40'
  };

  const bottomStyle = {
    fontSize: 11,
    textTransform: 'uppercase',
    fontFamily: 'sans-serif',
    color: 'gray',
    width: 90
  };

  const bottomTextStyle = {
    fontSize: 12,
    textAlign: 'left',
    marginLeft: 15,
    color: 'white',
    fontWeight: 'bold',
    fontFamily: 'sans-serif',
    textTransform: 'uppercase'
  };

  const personalSummaryInfo = props.personalReport;
  const teamProfileInfo = props.teamProfile;
  const data01 = [
    { name: 'E', value: personalSummaryInfo.ex_total },
    { name: 'I', value: personalSummaryInfo.it_total },
    { name: 'S', value: personalSummaryInfo.ss_total },
    { name: 'N', value: personalSummaryInfo.nt_total },
    { name: 'T', value: personalSummaryInfo.th_total },
    { name: 'F', value: personalSummaryInfo.fl_total },
    { name: 'J', value: personalSummaryInfo.jd_total },
    { name: 'P', value: personalSummaryInfo.pc_total }
  ];
  //((personalSummaryInfo.ex_total * 100) / (personalSummaryInfo.ex_total + personalSummaryInfo.it_total))
  const percentage = {
    E: (
      (personalSummaryInfo.ex_total * 100) /
      (personalSummaryInfo.ex_total + personalSummaryInfo.it_total)
    ).toFixed(0),
    I: (
      (personalSummaryInfo.it_total * 100) /
      (personalSummaryInfo.ex_total + personalSummaryInfo.it_total)
    ).toFixed(0),
    S: (
      (personalSummaryInfo.ss_total * 100) /
      (personalSummaryInfo.ss_total + personalSummaryInfo.nt_total)
    ).toFixed(0),
    N: (
      (personalSummaryInfo.nt_total * 100) /
      (personalSummaryInfo.ss_total + personalSummaryInfo.nt_total)
    ).toFixed(0),
    T: (
      (personalSummaryInfo.th_total * 100) /
      (personalSummaryInfo.fl_total + personalSummaryInfo.th_total)
    ).toFixed(0),
    F: (
      (personalSummaryInfo.fl_total * 100) /
      (personalSummaryInfo.fl_total + personalSummaryInfo.th_total)
    ).toFixed(0),
    J: (
      (personalSummaryInfo.jd_total * 100) /
      (personalSummaryInfo.jd_total + personalSummaryInfo.pc_total)
    ).toFixed(0),
    P: (
      (personalSummaryInfo.pc_total * 100) /
      (personalSummaryInfo.jd_total + personalSummaryInfo.pc_total)
    ).toFixed(0)
  };

  const rangeIdentifier = (indexPos) => {
    let rangeVal;
    if (
      percentage[personalSummaryInfo.type.toString().charAt(indexPos)] >= 50 &&
      percentage[personalSummaryInfo.type.toString().charAt(indexPos)] <= 60
    ) {
      if (
        !(
          percentage[personalSummaryInfo.type.toString().charAt(indexPos)] % 10
        ) == 0
      ) {
        rangeVal = `${
          percentage[personalSummaryInfo.type.toString().charAt(indexPos)] % 10
        }%`;
      } else {
        rangeVal = `${
          percentage[personalSummaryInfo.type.toString().charAt(indexPos)] == 50
            ? 0
            : 10.5
        }%`;
      }
    } else if (
      percentage[personalSummaryInfo.type.toString().charAt(indexPos)] >= 60 &&
      percentage[personalSummaryInfo.type.toString().charAt(indexPos)] <= 70
    ) {
      if (
        !(
          percentage[personalSummaryInfo.type.toString().charAt(indexPos)] % 10
        ) == 0
      ) {
        rangeVal = `${
          (percentage[personalSummaryInfo.type.toString().charAt(indexPos)] %
            10) +
          10.5
        }%`;
      } else {
        rangeVal = `${
          percentage[personalSummaryInfo.type.toString().charAt(indexPos)] == 60
            ? 10.5
            : 21
        }%`;
      }
    } else if (
      percentage[personalSummaryInfo.type.toString().charAt(indexPos)] >= 70 &&
      percentage[personalSummaryInfo.type.toString().charAt(indexPos)] <= 80
    ) {
      if (
        !(
          percentage[personalSummaryInfo.type.toString().charAt(indexPos)] % 10
        ) == 0
      ) {
        rangeVal = `${
          (percentage[personalSummaryInfo.type.toString().charAt(indexPos)] %
            10) /
            2 +
          21
        }%`;
      } else {
        rangeVal = `${
          percentage[personalSummaryInfo.type.toString().charAt(indexPos)] == 70
            ? 21
            : 25.5
        }%`;
      }
    } else if (
      percentage[personalSummaryInfo.type.toString().charAt(indexPos)] >= 80 &&
      percentage[personalSummaryInfo.type.toString().charAt(indexPos)] <= 90
    ) {
      if (
        !(
          percentage[personalSummaryInfo.type.toString().charAt(indexPos)] % 10
        ) == 0
      ) {
        rangeVal = `${
          (percentage[personalSummaryInfo.type.toString().charAt(indexPos)] %
            10) +
          25.5
        }%`;
      } else {
        rangeVal = `${
          percentage[personalSummaryInfo.type.toString().charAt(indexPos)] == 80
            ? 25.5
            : 31.5
        }%`;
      }
    } else {
      if (
        !(
          percentage[personalSummaryInfo.type.toString().charAt(indexPos)] % 10
        ) == 0
      ) {
        rangeVal = `${
          (percentage[personalSummaryInfo.type.toString().charAt(indexPos)] %
            10) +
          31.5
        }%`;
      } else {
        rangeVal = `${
          percentage[personalSummaryInfo.type.toString().charAt(indexPos)] == 90
            ? 31.5
            : 42
        }%`;
      }
    }
    return rangeVal;
  };

  const eiPercent = rangeIdentifier(0);
  const snPercent = rangeIdentifier(1);
  const tfPercent = rangeIdentifier(2);
  const jpPercent = rangeIdentifier(3);

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${data01[index].value}`}
      </text>
    );
  };
  const COLORS = [
    '#C0392B',
    '#D98880',
    '#F39C12',
    '#F8C471',
    '#3498DB',
    '#AED6F1',
    '#16A085',
    '#73C6B6'
  ];

  const data02 = [
    { name: 'E', value: teamProfileInfo.exTeamTotal },
    { name: 'I', value: teamProfileInfo.itTeamTotal },
    { name: 'S', value: teamProfileInfo.ssTeamTotal },
    { name: 'N', value: teamProfileInfo.ntTeamTotal },
    { name: 'T', value: teamProfileInfo.thTeamTotal },
    { name: 'F', value: teamProfileInfo.flTeamTotal },
    { name: 'J', value: teamProfileInfo.jdTeamTotal },
    { name: 'P', value: teamProfileInfo.pcTeamTotal }
  ];
  const renderCustomizedLabel2 = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.6;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {`${data02[index].name}`}
      </text>
    );
  };
  return (
    <>
      <Link
        href="#"
        onClick={() => {
          html2canvas(document.getElementById('biDirectionalChart')).then(
            function (canvas) {
              canvas.toBlob(function (blob) {
                navigator.clipboard
                  .write([
                    new ClipboardItem(
                      Object.defineProperty({}, blob.type, {
                        value: blob,
                        enumerable: true
                      })
                    )
                  ])
                  .then(function () {
                    console.log('Copied to clipboard');
                    // domNode.classList.remove('on');
                  });
              });
            }
          );
        }}
      >
        {`Copy`}
      </Link>
      <div id="biDirectionalChart">
        <Card
          id="biDirectionalChart"
          style={{
            marginTop: 40,
            marginBottom: 40,
            width: '70%'
          }}
        >
          <CardContent>
            <Grid
              container
              xs={12}
              sm={12}
              spacing={0.2}
              style={{ marginTop: 10 }}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item></Grid>
              <Grid item justifyContent="center">
                <div
                  style={{
                    ...headingStyle,
                    ...{ width: 100, textAlign: 'center' }
                  }}
                >
                  Very Clear
                </div>
              </Grid>
              <Grid item justifyContent="center">
                <div
                  style={{
                    ...headingStyle,
                    ...{ width: 100, textAlign: 'center' }
                  }}
                >
                  Clear
                </div>
              </Grid>
              <Grid item>
                <div
                  style={{
                    ...headingStyle,
                    ...{ width: 100, textAlign: 'center' }
                  }}
                >
                  Moderate
                </div>
              </Grid>
              <Grid item>
                <div
                  style={{
                    ...headingStyle,
                    ...{ width: 200, textAlign: 'center' }
                  }}
                >
                  Slight
                </div>
              </Grid>
              {/* <Grid item>
          <div
            style={{ ...headingStyle, ...{ width: 100, textAlign: 'center' } }}
          >
            Slight
          </div>
        </Grid> */}
              <Grid item>
                <div
                  style={{
                    ...headingStyle,
                    ...{ width: 100, textAlign: 'center' }
                  }}
                >
                  Moderate
                </div>
              </Grid>
              <Grid item>
                <div
                  style={{
                    ...headingStyle,
                    ...{ width: 100, textAlign: 'center' }
                  }}
                >
                  Clear
                </div>
              </Grid>
              <Grid item>
                <div
                  style={{
                    ...headingStyle,
                    ...{ width: 100, textAlign: 'center' }
                  }}
                >
                  Very Clear
                </div>
              </Grid>
              <Grid item></Grid>
            </Grid>
            <Grid
              container
              xs={12}
              sm={12}
              spacing={0.2}
              style={{ marginTop: 20 }}
              justifyContent="center"
              alignItem="center"
            >
              <div
                style={{
                  position: 'fixed',
                  // marginLeft: `4%`,
                  marginLeft:
                    personalSummaryInfo.type.toString().charAt(0) == 'E'
                      ? `-${eiPercent}`
                      : `${eiPercent}`,
                  width: 5,
                  height: 15,
                  borderWidth: 1,
                  backgroundColor: '#034fa6'
                }}
              ></div>
              <Grid item>
                <div style={textStyle}>Extroversion</div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#eda831' } }}
                ></div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#f3c261' } }}
                ></div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#f8dca0' } }}
                ></div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#ffe6a0' } }}
                ></div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#ffe6a0' } }}
                ></div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#f8dca0' } }}
                ></div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#f3c261' } }}
                ></div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#eda831' } }}
                ></div>
              </Grid>
              <Grid item>
                <div style={{ ...textStyle, ...{ color: 'gray' } }}>
                  Introversion
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              sm={12}
              spacing={0.2}
              style={{ marginTop: 15 }}
              justifyContent="center"
              alignItem="center"
            >
              <div
                style={{
                  position: 'fixed',
                  marginLeft:
                    personalSummaryInfo.type.toString().charAt(1) == 'S'
                      ? `-${snPercent}`
                      : `${snPercent}`,
                  width: 5,
                  height: 15,
                  borderWidth: 1,
                  backgroundColor: '#034fa6'
                }}
              ></div>
              <Grid item>
                <div style={textStyle}>Sensing</div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#eda831' } }}
                ></div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#f3c261' } }}
                ></div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#f8dca0' } }}
                ></div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#ffe6a0' } }}
                ></div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#ffe6a0' } }}
                ></div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#f8dca0' } }}
                ></div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#f3c261' } }}
                ></div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#eda831' } }}
                ></div>
              </Grid>
              <Grid item>
                <div style={{ ...textStyle, ...{ color: 'gray' } }}>
                  Intuition
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              sm={12}
              spacing={0.2}
              style={{ marginTop: 15 }}
              justifyContent="center"
              alignItem="center"
            >
              <div
                style={{
                  position: 'fixed',
                  marginLeft:
                    personalSummaryInfo.type.toString().charAt(2) == 'T'
                      ? `-${tfPercent}`
                      : `${tfPercent}`,
                  width: 5,
                  height: 15,
                  borderWidth: 1,
                  backgroundColor: '#034fa6'
                }}
              ></div>
              <Grid item>
                <div style={textStyle}>Thinking</div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#eda831' } }}
                ></div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#f3c261' } }}
                ></div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#f8dca0' } }}
                ></div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#ffe6a0' } }}
                ></div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#ffe6a0' } }}
                ></div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#f8dca0' } }}
                ></div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#f3c261' } }}
                ></div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#eda831' } }}
                ></div>
              </Grid>
              <Grid item>
                <div style={{ ...textStyle, ...{ color: 'gray' } }}>
                  Feeling
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              sm={12}
              spacing={0.2}
              style={{ marginTop: 15 }}
              justifyContent="center"
              alignItem="center"
            >
              <div
                style={{
                  position: 'fixed',
                  marginLeft:
                    personalSummaryInfo.type.toString().charAt(3) == 'J'
                      ? `-${jpPercent}`
                      : `${jpPercent}`,
                  width: 5,
                  height: 15,
                  borderWidth: 1,
                  backgroundColor: '#034fa6'
                }}
              ></div>
              <Grid item>
                <div style={textStyle}>Judging</div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#eda831' } }}
                ></div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#f3c261' } }}
                ></div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#f8dca0' } }}
                ></div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#ffe6a0' } }}
                ></div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#ffe6a0' } }}
                ></div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#f8dca0' } }}
                ></div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#f3c261' } }}
                ></div>
              </Grid>
              <Grid item>
                <div
                  style={{ ...boxStyle, ...{ backgroundColor: '#eda831' } }}
                ></div>
              </Grid>
              <Grid item>
                <div style={{ ...textStyle, ...{ color: 'gray' } }}>
                  Perceiving
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              xs={12}
              sm={12}
              spacing={0.2}
              style={{ marginTop: 10 }}
              justifyContent="center"
              alignItems="center"
            >
              <Grid item></Grid>
              <Grid item justifyContent="center">
                <div
                  style={{
                    ...headingStyle,
                    ...{ width: 100, textAlign: 'center' }
                  }}
                >
                  100-90
                </div>
              </Grid>
              <Grid item justifyContent="center">
                <div
                  style={{
                    ...headingStyle,
                    ...{ width: 100, textAlign: 'center' }
                  }}
                >
                  90-70
                </div>
              </Grid>
              <Grid item>
                <div
                  style={{
                    ...headingStyle,
                    ...{ width: 100, textAlign: 'center' }
                  }}
                >
                  70-60
                </div>
              </Grid>
              <Grid item>
                <div
                  style={{
                    ...headingStyle,
                    ...{ width: 200, textAlign: 'center' }
                  }}
                >
                  50
                </div>
              </Grid>
              {/* <Grid item>
          <div
            style={{ ...headingStyle, ...{ width: 100, textAlign: 'center' } }}
          >
            Slight
          </div>
        </Grid> */}
              <Grid item>
                <div
                  style={{
                    ...headingStyle,
                    ...{ width: 100, textAlign: 'center' }
                  }}
                >
                  60-70
                </div>
              </Grid>
              <Grid item>
                <div
                  style={{
                    ...headingStyle,
                    ...{ width: 100, textAlign: 'center' }
                  }}
                >
                  70-90
                </div>
              </Grid>
              <Grid item>
                <div
                  style={{
                    ...headingStyle,
                    ...{ width: 100, textAlign: 'center' }
                  }}
                >
                  90-100
                </div>
              </Grid>
              <Grid item></Grid>
            </Grid>
            <div>
              <Grid
                container
                xs={12}
                sm={12}
                spacing={0.2}
                justifyContent="center"
                alignItem="center"
                alignSelf="center"
                flexDirection="row"
                style={{
                  marginTop: 20,
                  backgroundColor: 'gray',
                  width: 500,
                  height: 50,
                  borderRadius: 5,
                  justifyContent: 'space-evenly',
                  alignItems: 'center',
                  flexDirection: 'row',
                  marginRight: 'auto',
                  marginLeft: 'auto'
                }}
              >
                <Grid item>
                  <div
                    style={bottomTextStyle}
                  >{`${personalSummaryInfo.type} `}</div>
                </Grid>
                <Grid item>
                  <div style={bottomTextStyle}>{`- `}</div>
                </Grid>
                <Grid item>
                  <div style={bottomTextStyle}>{`${personalSummaryInfo.type
                    .toString()
                    .charAt(0)} | ${
                    percentage[personalSummaryInfo.type.toString().charAt(0)]
                  } %`}</div>
                </Grid>
                <div style={bottomTextStyle}>{`${personalSummaryInfo.type
                  .toString()
                  .charAt(1)} | ${
                  percentage[personalSummaryInfo.type.toString().charAt(1)]
                } %`}</div>
                <div style={bottomTextStyle}>{`${personalSummaryInfo.type
                  .toString()
                  .charAt(2)} | ${
                  percentage[personalSummaryInfo.type.toString().charAt(2)]
                } %`}</div>
                <div style={bottomTextStyle}>{`${personalSummaryInfo.type
                  .toString()
                  .charAt(3)} | ${
                  percentage[personalSummaryInfo.type.toString().charAt(3)]
                } %`}</div>
              </Grid>
            </div>
          </CardContent>
        </Card>
      </div>
      <Grid container xs={12} sm={12} spacing={3} justifyContent="space-around">
        <Grid item>
          <Card {...props}>
            <CardContent>
              <Typography color="primary" gutterBottom variant="h6">
                Personality Assessment
              </Typography>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item>
                  <Typography color="primary" gutterBottom variant="h2">
                    {personalSummaryInfo.type}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                alignItems="center"
                xs={12}
                sm={12}
                justifyContent="center"
              >
                <Grid item>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary={`EI CUSP - ${personalSummaryInfo.cusp['EI_CUSP']}`}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={`SN CUSP - ${personalSummaryInfo.cusp['SN_CUSP']}`}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={`TF CUSP - ${personalSummaryInfo.cusp['TF_CUSP']}`}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={`JP CUSP - ${personalSummaryInfo.cusp['JP_CUSP']}`}
                      />
                    </ListItem>
                  </List>
                </Grid>

                <Grid item>
                  <PieChart width={200} height={200}>
                    <Pie
                      dataKey="value"
                      isAnimationActive={true}
                      data={data01}
                      labelLine={false}
                      label={renderCustomizedLabel}
                      outerRadius={80}
                      fill="#8884d8"
                    >
                      {data01.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend
                      align="left"
                      wrapperStyle={{
                        position: 'relative',
                        marginLeft: '-50px',
                        marginTop: '10px'
                      }}
                      width={300}
                    />
                  </PieChart>
                </Grid>

                <Grid item>
                  <List>
                    {Object.keys(personalSummaryInfo.range).map((rangeInfo) => {
                      return (
                        <ListItem>
                          <ListItemText
                            primary={`${rangeInfo} - ${personalSummaryInfo.range[rangeInfo]} - ${percentage[rangeInfo]}%`}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item>
          <Card {...props}>
            <CardContent>
              <Typography color="primary" gutterBottom variant="h6">
                Team Profile
              </Typography>
              <Grid container alignItems="center" justifyContent="center">
                <Grid item>
                  <Typography color="primary" gutterBottom variant="h2">
                    {teamProfileInfo.teamProfile}
                  </Typography>
                </Grid>
              </Grid>

              <Grid
                container
                alignItems="center"
                xs={12}
                sm={12}
                justifyContent="center"
              >
                <Grid item>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary={`Extroverts - ${teamProfileInfo.exCount}`}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={`Introverts - ${teamProfileInfo.itCount}`}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={`Sensors - ${teamProfileInfo.ssCount}`}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={`Intuitives - ${teamProfileInfo.ntCount}`}
                      />
                    </ListItem>
                  </List>
                </Grid>

                <Grid item>
                  <PieChart width={200} height={200}>
                    <Pie
                      dataKey="value"
                      isAnimationActive={true}
                      data={data02}
                      labelLine={false}
                      label={renderCustomizedLabel2}
                      outerRadius={80}
                      fill="#8884d8"
                    >
                      {data01.map((entry, index) => (
                        <Cell
                          key={`cell-${index}`}
                          fill={COLORS[index % COLORS.length]}
                        />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend
                      align="left"
                      wrapperStyle={{
                        position: 'relative',
                        marginLeft: '-50px',
                        marginTop: '10px'
                      }}
                      width={300}
                    />
                  </PieChart>
                </Grid>

                <Grid item>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary={`Thinkers - ${teamProfileInfo.thCount}`}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={`Feelers - ${teamProfileInfo.flCount}`}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={`Judgers - ${teamProfileInfo.jdCount}`}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary={`Perceivers - ${teamProfileInfo.pcCount}`}
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default PersonalSummary;
