import { get } from 'lodash';
import { setAuthCookie } from 'src/utils/cookie';
import * as UserServices from 'src/services/UserServices';
import { receiveErrors, clearErrors } from '../error/error.actions';
import UserActionTypes from './user.types';

const setCurrentUser = (user) => ({
  type: UserActionTypes.SET_CURRENT_USER,
  payload: user
});
export const setOriginalPath = (path) => ({
  type: UserActionTypes.SET_ORIGNAL_PATH,
  payload: path
});
export const logoutCurrentUser = () => ({
  type: UserActionTypes.LOGOUT_CURRENT_USER,
  payload: { isSessionValid: false }
});

const setSessionValidated = (isSessionValid) => ({
  type: UserActionTypes.SET_SESSION_VALIDATED,
  payload: { isSessionValid }
});

const setUserType = (usertype) => ({
  type: UserActionTypes.SET_USER_TYPE,
  payload: usertype
});

export const setLoading = (loading) => ({
  type: UserActionTypes.SET_LOADING,
  payload: loading
});
// export const signup = (user) => async (dispatch) => {
//   const response = await UserServices.signup(user);

//   if (response.status === 200) {
//     return dispatch(setCurrentUser(response.data));
//   }
//   return dispatch(receiveErrors(response.data));
// };

export const login = (user) => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(clearErrors());
    const response = await UserServices.login(user);
    setAuthCookie(get(response, 'zid'));
    dispatch(setSessionValidated(true));
    const userInfo = await UserServices.getUserInfo();
    dispatch(setLoading(false));
    dispatch(setUserType('Admin'));
    return dispatch(setCurrentUser(userInfo));
  } catch (error) {
    dispatch(setSessionValidated(false));
    dispatch(setLoading(false));
    dispatch(receiveErrors(error));
    throw error;
  }
};

// export const logout = () => async (dispatch) => {
// const response = await UserServices.logout();
//   dispatch(logoutCurrentUser());
// if (response.status === 200) {
//   return dispatch(logoutCurrentUser());
// }
// return dispatch(receiveErrors(response.data));
// };

// export const getUserInfo = () => async (dispatch) => {
//   const response = await UserServices.logout();

//   if (response.status === 200) {
//     dispatch(setSessionValidated(true));
//     return dispatch(logoutCurrentUser());
//   }
//   return dispatch(receiveErrors(response.data));
// };
